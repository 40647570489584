<template>
  <div v-if="isVisible">
    <!-- SECCION ENCUENTRA LA FINCA IDEAL PARA TI -->

    <!-- CONTENEDOR DE FILTROS -->
    <div class="bg-agrogo-green-2 pb-10 pt-5 xl:pt-5">
      <!-- FILTROS SELECCIONADOS -->
      <div v-if="filterSelect.length > 0">
        <div class="flex md:justify-center">
          <div class="w-full md:w-9/12 relative flex py-2 items-center">
            <span
              class="flex-shrink mx-4 text-white text-lg font-nexa font-normal"
              >Filtros seleccionados</span
            >
            <div
              class="flex-grow border border-agrogo-yellow-1 mr-3 xl:mr-0"
            ></div>
          </div>
        </div>
        <div class="flex justify-center mb-5">
          <div class="w-10/12 xl:w-9/12 relative flex py-2">
            <div class="flex flex-wrap">
              <div
                class="border border-agrogo-yellow-1 mr-3 py-1 px-2 mt-2 rounded-lg"
                v-for="item in filterSelect"
                :key="item.id"
              >
                <p
                  class="text-agrogo-yellow-1 font-nexa font-normal flex text-xs xl:text-lg"
                >
                  {{ item.text }}
                  <button
                    @click="
                      deleteSelectFilter(
                        item.id,
                        item.typeFilter,
                        item.selectId
                      )
                    "
                    class="icon-x text-agrogo-yellow-1 text-xs xl:text-sm transform transition-all ml-2 border-none mt-0"
                  ></button>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="flex md:justify-center mb-5">
          <div class="w-full md:w-9/12 relative flex py-2 items-center">
            <span
              class="flex-shrink mx-4 text-white text-lg font-nexa font-normal"
              >Lista de filtros</span
            >
            <div
              class="flex-grow border border-agrogo-yellow-1 mr-3 xl:mr-0"
            ></div>
          </div>
        </div>
      </div>
      <!--ACTIVAR O DESACTIVAR FORMULARIO TIPO DE FINCA -->
      <div class="flex md:justify-center">
        <div
          class="w-full mx-3 mx-md:0 md:w-9/12 bg-agrogo-green-5 h-8 xl:h-14 flex justify-between items-center"
        >
          <div
            class="flex w-1/2 ml-2 justify-start cursor-pointer"
            @click.prevent="typeFarm"
          >
            <a class="flex w-full text-white text-lg md:text-2xl font-dinpro">
              <span
                class="icon-flower text-white text-base md:text-xl flex w-1/5 md:w-2/12 xl:w-1/12 justify-center items-center"
              ></span>
              <span class="bold-text">Tipo de Finca</span>
            </a>
          </div>
          <button
            @click.prevent="typeFarm"
            class="h-8 xl:h-14 w-14 bg-agrogo-yellow-1 btn-primary rounded-none py-0"
          >
            <span
              v-if="!stateFilter.typeFarm"
              class="icon-Publicar text-black text-sm xl:text-2xl transform transition-all"
            ></span>
            <span
              v-else
              class="icon-menos text-black text-sm xl:text-2xl transform transition-all"
            ></span>
          </button>
        </div>
      </div>
      <!-- FORMULARIO TIPO DE FINCA -->
      <div v-if="stateFilter.typeFarm" class="flex justify-center">
        <div
          class="w-full mx-3 mx-md:0 xl:w-9/12 flex xl:justify-between items-center mt-5"
        >
          <div
            class="m-auto grid grid-cols-3 xl:grid-cols-5 gap-6 xl:gap-5 w-full place-items-center xl:w-5/6 xl:mx-auto"
          >
            <div
              class="card-farm-filter cursor-pointer"
              :id="'card-farm-filter' + item.id"
              v-for="item in category.subcategories"
              :key="item.id"
              @click.prevent="addRemove(item.id, item.name)"
              :class="{ 'active-card-farm-filter': item.isActive }"
            >
              <div class="flex flex-col items-center">
                <div class="text-2xl md:text-5xl mb-2 mt-3 md:mt-10">
                  <span :class="item.icon"></span>
                </div>
                <a
                  class="font-nexa font-normal mb-2 text-xs md:text-lg text-white pt-2 md:pt-0"
                  >{{ item.name }}</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- ACTIVAR O DESACTIVAR FORMULARIO UBICACION  -->
      <div class="flex justify-center">
        <div
          class="w-full mx-3 mx-md:0 md:w-9/12 bg-agrogo-green-5 h-8 xl:h-14 flex justify-between items-center mt-7"
        >
          <div
            class="flex w-1/2 ml-2 justify-start cursor-pointer"
            @click.prevent="locationFarm"
          >
            <a class="flex w-full text-white text-lg md:text-2xl font-dinpro">
              <span
                class="icon-ubicacion text-white text-lg md:text-2xl flex w-1/5 md:w-2/12 xl:w-1/12 justify-center"
              ></span>
              <span class="flex bold-text w-4/5">Ubicación</span>
            </a>
          </div>
          <button
            class="h-8 xl:h-14 w-14 bg-agrogo-yellow-1 btn-primary rounded-none py-0"
            @click.prevent="locationFarm"
          >
            <span
              v-if="!stateFilter.location"
              class="icon-Publicar text-black text-sm xl:text-2xl transform transition-all"
            ></span>
            <span
              v-else
              class="icon-menos text-black text-sm xl:text-2xl transform transition-all"
            ></span>
          </button>
        </div>
      </div>
      <!-- CONTAINER DEPARTAMENTO MUNICIPIO-->
      <div v-if="stateFilter.location" class="flex justify-center mt-3">
        <div class="w-full md:w-9/12">
          <div class="font-nexa text-agrogo-yellow-1 text-sm lg:text-base mb-4">
            Selecciona los departamentos y municipios que desees.
          </div>
          <div class="grid gap-4 grid-cols-1 xl:grid-cols-2">
            <div class="bg-agrogo-green-5-filter-farm xl:py-7 xl:pr-6">
              <div class="grid grid-cols-2 gap-x-8 h-full">
                <div class="flex items-center justify-start">
                  <p
                    class="text-white text-sm xl:text-lg font-nexa font-normal xl:font-bold ml-12 xl:ml-16"
                  >
                    Departamento
                  </p>
                </div>

                <div class="mr-10 flex items-center justify-start">
                  <v-select
                    :options="departments"
                    v-model="selectedDepart"
                    class="style-chooser3 style-chooser4 input-filter-farm-width style-chooser-listoffarms style-chooser-listoffarms2"
                    label="name"
                    id="departments"
                    :close-on-select="false"
                    multiple
                    @update:modelValue="loadMunicipies()"
                  >
                    <template #no-options>Sin opciones</template>
                  </v-select>
                </div>
              </div>
            </div>
            <div class="bg-agrogo-green-5-filter-farm xl:py-7 xl:pr-6">
              <div class="grid xl:gap-2 grid-cols-2 gap-x-8 h-full">
                <div class="flex items-center justify-start">
                  <p
                    class="text-white text-sm xl:text-lg font-nexa font-normal xl:font-bold ml-12 xl:ml-16"
                  >
                    Municipio
                  </p>
                </div>

                <div class="flex items-center justify-start mr-10">
                  <v-select
                    :options="municipalities"
                    v-model="selectedMunicipality"
                    :close-on-select="false"
                    id="municipies"
                    class="style-chooser3 style-chooser4 input-filter-farm-width style-chooser-listoffarms style-chooser-listoffarms2"
                    label="name"
                    multiple
                  >
                    <template #no-options>Sin opciones</template>
                  </v-select>
                </div>
              </div>
            </div>
            <!-- FILTRO Distancia via pavimentada-->
            <div class="bg-agrogo-green-5-filter-farm row-span-2 mt-14 xl:mt-3">
              <div class="grid xl:gap-2 grid-cols-1 xl:grid-cols-2">
                <div class="flex">
                  <p
                    class="text-white sm:text-lg font-nexa font-normal mt-0 xl:mt-4 mr-1 ml-11 xl:ml-16 text-left"
                  >
                    Distancia via pavimentada
                    <span class="text-agrogo-gray-1 text-xs lg:text-base"
                      >(Km)</span
                    >
                  </p>
                </div>
                <div class="pt-2 grid grid-cols-1 xl:grid-cols-2 xl:mr-10">
                  <div
                    class="mt-2 grid grid-cols-2 xl:grid-cols-1 gap-x-2 mx-11 xl:ml-3 xl:mx-0"
                  >
                    <p
                      class="text-white font-nexa text-sm sm:text-base text-left xl:text-center mr-2 xl:mr-0 ml-6 xl:ml-0"
                    >
                      Desde
                    </p>
                    <input
                      id="distanceFrom"
                      placeholder="0"
                      v-model="distanceFrom"
                      @keyup="
                        addEditDelSelectFilter(
                          null,
                          'distanceFrom',
                          distanceFrom,
                          'distanceFrom'
                        )
                      "
                      @blur="validationDistanceMin"
                      type="text"
                      class="input-standard input-filter-farm-width xl:ml-0"
                    />
                  </div>
                  <div
                    class="mt-2 grid grid-cols-2 xl:grid-cols-1 gap-x-2 mb-3 mx-11 xl:ml-3 xl:mx-0 xl:mb-0"
                  >
                    <p
                      class="text-white font-nexa text-sm sm:text-base text-left xl:text-center mr-2 xl:mr-0 ml-6 xl:ml-0"
                    >
                      Hasta
                    </p>
                    <input
                      id="distanceUntil"
                      v-model="distanceUntil"
                      placeholder="0"
                      @keyup="
                        addEditDelSelectFilter(
                          null,
                          'distanceUntil',
                          distanceUntil,
                          'distanceUntil'
                        )
                      "
                      @blur="validationDistanceMax"
                      type="text"
                      class="input-standard input-filter-farm-width xl:ml-0"
                    />
                  </div>
                </div>
              </div>
              <div class="mt-1 mb-1">
                <p
                  class="text-xs md:text-base lg:text-base text-agrogo-yellow-2 font-nexa block text-center"
                  v-if="isValidationDistanceMax"
                >
                  La distacia Hasta no puede ser inferior a la Desde.
                </p>
                <p
                  class="text-xs md:text-base lg:text-base text-agrogo-yellow-2 font-nexa block text-center"
                  v-else-if="isValidationDistanceMin"
                >
                  La distacia Desde no puede ser superior a la Hasta.
                </p>
                <p
                  class="text-xs md:text-base lg:text-base text-agrogo-yellow-2 font-nexa block text-center"
                  v-else
                >
                  &nbsp;
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- ACTIVAR O DESACTIVAR FORMULARIO PRECIO -->
      <div class="flex justify-center">
        <div
          class="w-full mx-3 mx-md:0 md:w-9/12 bg-agrogo-green-5 h-8 xl:h-14 flex justify-between items-center mt-7"
        >
          <div
            class="flex w-1/2 ml-2 justify-start cursor-pointer"
            @click.prevent="moneyFarm"
          >
            <a class="flex w-full text-white text-lg md:text-2xl font-dinpro">
              <span
                class="icon-signo-pesos text-white text-lg md:text-2xl flex w-1/5 md:w-2/12 xl:w-1/12 justify-center items-center"
              ></span>
              <span class="flex bold-text">Precio</span>
            </a>
          </div>
          <button
            class="h-8 xl:h-14 w-14 bg-agrogo-yellow-1 btn-primary rounded-none py-0"
            @click.prevent="moneyFarm"
          >
            <span
              v-if="!stateFilter.money"
              class="icon-Publicar text-black text-sm xl:text-2xl transform transition-all"
            ></span>
            <span
              v-else
              class="icon-menos text-black text-sm xl:text-2xl transform transition-all"
            ></span>
          </button>
        </div>
      </div>
      <!-- CONTAINER  PRECIO-->
      <div v-if="stateFilter.money" class="flex justify-center mt-3">
        <div class="w-full md:w-9/12">
          <div class="grid gap-4 grid-cols-1 xl:grid-cols-2">
            <!-- FILTRO POR HECTAREAS-->
            <div class="bg-agrogo-green-5-filter-farm xl:pr-6 xl:pl-14">
              <div>
                <div class="input-flex-column ml-12 xl:ml-0">
                  <div class="flex">
                    <p
                      class="text-white sm:text-lg font-nexa font-normal mt-1 xl:mt-4 mr-1"
                    >
                      Área
                    </p>
                    <div class="tooltip-container mt-1 xl:mt-4">
                      <i
                        class="fas fa-question-circle tooltip-trigger text-white"
                      >
                        <span
                          class="icon-Group text-agrogo-yellow-2 text-lg md:text-2xl ml-1.5 transform transition-all"
                        ></span>
                      </i>
                      <div class="tooltip-step3">1 cuadra = 0.64 hectáreas</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="grid gap-2 grid-cols-2 ml-16 mr-10 xl:ml-0">
                <div class="flex items-center justify-start">
                  <span
                    class="text-agrogo-gray-1 text-sm font-nexa font-normal"
                  >
                    Hectáreas
                  </span>
                </div>
                <div class="">
                  <div
                    class="flex items-center justify-center md:justify-start space-x-2"
                  >
                    <div>
                      <p
                        class="text-agrogo-gray-1 font-dinpro font-normal text-xs sm:text-base text-center"
                      >
                        Desde
                      </p>
                      <div class="flex items-center justify-center">
                        <input
                          id="areaFrom"
                          placeholder="0"
                          v-model="dataMoney.areaFrom"
                          @keyup="
                            addEditDelSelectFilter(
                              null,
                              'areaFrom',
                              dataMoney.areaFrom,
                              'areaFrom'
                            )
                          "
                          @blur="validationArea1()"
                          type="text"
                          class="input-standard max-w-110"
                          pattern="[0-9]+"
                        />
                      </div>
                    </div>
                    <div>
                      <p
                        class="text-agrogo-gray-1 font-dinpro font-normal text-xs sm:text-base text-center"
                      >
                        Hasta
                      </p>
                      <div class="flex items-center justify-start">
                        <input
                          id="areaUntil"
                          placeholder="0"
                          @keyup="
                            addEditDelSelectFilter(
                              null,
                              'areaUntil',
                              dataMoney.areaUntil,
                              'areaUntil'
                            )
                          "
                          @blur="validationArea2()"
                          v-model="dataMoney.areaUntil"
                          type="text"
                          class="input-standard max-w-110"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-1 mb-1">
                <p
                  class="text-xs md:text-base lg:text-base text-agrogo-yellow-2 font-nexa block text-center"
                  v-if="isValidationArea2"
                >
                  El área Hasta no debe ser inferior al Desde.
                </p>
                <p
                  class="text-xs md:text-base lg:text-base text-agrogo-yellow-2 font-nexa block text-center pl-2"
                  v-else-if="isValidationArea1"
                >
                  El área Desde no debe ser superior al Hasta.
                </p>
                <p
                  class="text-xs md:text-base lg:text-base text-agrogo-yellow-2 font-nexa block text-center"
                  v-else
                >
                  &nbsp;
                </p>
              </div>
              <div class="grid grid-cols-2 mb-3 ml-16 mr-10 xl:ml-0 gap-x-2">
                <div class="flex items-center">
                  <p
                    class="text-white text-sm font-dinpro font-normal text-left"
                  >
                    Cuadras, Plazas, Fanegadas.
                  </p>
                </div>
                <div
                  class="flex items-center justify-center md:justify-start space-x-2"
                >
                  <input
                    type="text"
                    :value="hectaresBlocks(dataMoney.areaFrom)"
                    disabled
                    class="input-standard w-full text-white bg-agrogo-gray-1-opacity max-w-110 cursor-not-allowed"
                  />
                  <input
                    type="text"
                    :value="hectaresBlocks(dataMoney.areaUntil)"
                    disabled
                    class="input-standard w-full text-white bg-agrogo-gray-1-opacity max-w-110 cursor-not-allowed"
                  />
                </div>
              </div>
            </div>
            <!-- FILTRO PRECIO POR HECTAREAS-->
            <div class="bg-agrogo-green-5-filter-farm xl:pr-6 xl:pl-14">
              <div class="grid grid-cols-1 xl:grid-cols-2 xl:mr-10">
                <div class="flex sm:flex-col text-left ml-11 xl:ml-0">
                  <p
                    class="text-white sm:text-lg font-nexa font-medium xl:font-bold mt-0 xl:mt-6 mr-1"
                  >
                    Precio por hectárea
                  </p>
                  <p
                    class="text-agrogo-gray-1 text-xs font-nexa font-normal mr-1 mt-1 sm:mt-0"
                  >
                    (Pesos Colombianos)
                  </p>
                </div>
                <div class="ml-16 mr-10 xl:ml-0 xl:mr-0">
                  <div
                    class="mt-2 xl:mt-6 grid grid-cols-2 xl:grid-cols-1 gap-x-2"
                  >
                    <p
                      class="font-dinpro text-sm xl:text-base text-agrogo-gray-1 text-left xl:text-center sm:w-56"
                    >
                      Desde
                    </p>
                    <div
                      class="flex items-center justify-center md:justify-start"
                    >
                      <input
                        id="priceHectareFrom"
                        placeholder="0"
                        @keyup="
                          addEditDelSelectFilter(
                            null,
                            'priceHectareFrom',
                            dataMoney.priceHectareFrom,
                            'priceHectareFrom'
                          )
                        "
                        @blur="validationPriceHect1"
                        type="text"
                        v-model="dataMoney.priceHectareFrom"
                        class="input-standard input-filter-farm-width-price sm:w-56"
                      />
                    </div>

                    <div>
                      <p
                        class="text-xs md:text-base lg:text-base text-agrogo-yellow-2 font-nexa block text-center"
                        v-if="isValidationPriceHect1"
                      >
                        El precio Desde no debe ser superior al Hasta.
                      </p>
                      <p
                        class="text-xs md:text-base lg:text-base text-agrogo-yellow-2 font-nexa block text-center"
                        v-else
                      >
                        &nbsp;
                      </p>
                    </div>
                  </div>

                  <div class="grid grid-cols-2 xl:grid-cols-1 gap-x-2">
                    <p
                      class="font-dinpro text-sm xl:text-base text-agrogo-gray-1 text-left xl:text-center sm:w-56"
                    >
                      Hasta
                    </p>
                    <div
                      class="flex items-center justify-center md:justify-start"
                    >
                      <input
                        id="priceHectareUntil"
                        placeholder="0"
                        @keyup="
                          addEditDelSelectFilter(
                            null,
                            'priceHectareUntil',
                            dataMoney.priceHectareUntil,
                            'priceHectareUntil'
                          )
                        "
                        @blur="validationPriceHect2"
                        v-model="dataMoney.priceHectareUntil"
                        type="text"
                        class="input-standard input-filter-farm-width-price sm:w-56"
                      />
                    </div>
                    <div>
                      <p
                        class="text-xs md:text-base lg:text-base text-agrogo-yellow-2 font-nexa block text-center"
                        v-if="isValidationPriceHect2"
                      >
                        El precio Hasta no debe ser inferior al Desde.
                      </p>
                      <p
                        class="text-xs md:text-base lg:text-base text-agrogo-yellow-2 font-nexa block text-center"
                        v-else
                      >
                        &nbsp;
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- FILTRO PRECIO TOTAL -->
            <div class="bg-agrogo-green-5-filter-farm xl:pr-6 xl:pl-14">
              <div class="grid grid-cols-1 xl:grid-cols-2 xl:mr-10">
                <div class="flex sm:flex-col text-left ml-11 xl:ml-0">
                  <p
                    class="text-white sm:text-lg font-nexa font-medium xl:font-bold xl:mt-6 mr-1"
                  >
                    Precio Total
                  </p>
                  <p
                    class="text-agrogo-gray-1 text-xs font-nexa font-normal mr-1 mt-1 sm:mt-0"
                  >
                    (Pesos Colombianos)
                  </p>
                </div>
                <div class="ml-16 mr-10 xl:ml-0 xl:mr-0">
                  <div
                    class="mt-2 xl:mt-6 grid grid-cols-2 xl:grid-cols-1 gap-x-2"
                  >
                    <p
                      class="font-dinpro text-sm xl:text-base text-agrogo-gray-1 text-left xl:text-center sm:w-56"
                    >
                      Desde
                    </p>
                    <div
                      class="flex items-center justify-center md:justify-start"
                    >
                      <input
                        id="priceTotalFrom"
                        placeholder="0"
                        @keyup="
                          addEditDelSelectFilter(
                            null,
                            'priceTotalFrom',
                            dataMoney.priceTotalFrom,
                            'priceTotalFrom'
                          )
                        "
                        @blur="validationPriceTotal1"
                        v-model="dataMoney.priceTotalFrom"
                        type="text"
                        class="input-standard input-filter-farm-width-price sm:w-56"
                      />
                    </div>
                    <div>
                      <p
                        class="text-xs md:text-base lg:text-base text-agrogo-yellow-2 font-nexa block text-center"
                        v-if="isValidationPriceTotal1"
                      >
                        El precio Desde no debe ser superior al Hasta.
                      </p>
                      <p
                        class="text-xs md:text-base lg:text-base text-agrogo-yellow-2 font-nexa block text-center"
                        v-else
                      >
                        &nbsp;
                      </p>
                    </div>
                  </div>
                  <div class="grid grid-cols-2 xl:grid-cols-1 gap-x-2 mb-2">
                    <p
                      class="font-dinpro text-sm xl:text-base text-agrogo-gray-1 text-left xl:text-center sm:w-56"
                    >
                      Hasta
                    </p>
                    <div
                      class="flex items-center justify-center md:justify-start"
                    >
                      <input
                        id="priceTotalUntil"
                        placeholder="0"
                        @keyup="
                          addEditDelSelectFilter(
                            null,
                            'priceTotalUntil',
                            dataMoney.priceTotalUntil,
                            'priceTotalUntil'
                          )
                        "
                        @blur="validationPriceTotal2"
                        v-model="dataMoney.priceTotalUntil"
                        type="text"
                        class="input-standard input-filter-farm-width-price sm:w-56"
                      />
                    </div>
                    <div>
                      <p
                        class="text-xs md:text-base lg:text-base text-agrogo-yellow-2 font-nexa block text-center"
                        v-if="isValidationPriceTotal2"
                      >
                        El precio Hasta no debe ser inferior al Desde.
                      </p>
                      <p
                        class="text-xs md:text-base lg:text-base text-agrogo-yellow-2 font-nexa block text-center"
                        v-else
                      >
                        &nbsp;
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- FILTRO PERMUTA  -->
            <!-- <div class="xl:h-48"> -->
            <!-- <div class="grid gap-2 grid-cols-1"> -->
            <!-- <div class="bg-agrogo-green-5-filter-farm h-16">
                  <div class="grid gap-2 grid-cols-2 text-left">
                    <p
                      class="text-white sm:text-lg font-nexa font-normal mt-4 mr-1 ml-11 xl:ml-16"
                    >
                      Permuta
                    </p>

                    <div class="flex justify-start lg:justify-center mt-4">
                      <div class="w-3/5 flex">
                        <div
                          class="flex items-center ml-3 sm:mr-14"
                          v-for="(option, index) in options"
                          :key="index"
                        >
                          <input
                            type="radio"
                            class="custom-radio-test"
                            v-model="barter"
                            :value="option.value"
                            :id="option.text + index"
                            :name="option.text + index"
                            @change="barterChange()"
                          /><label
                            :for="option.text + index"
                            class="text-white text-sm font-dinpro font-normal block ml-2 hover:text-agrogo-yellow-1"
                            >{{ option.text }}</label
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div> -->
            <!-- <div
                  class="bg-agrogo-green-5-filter-farm h-16"
                  v-if="isPercentage"
                >
                  <div class="grid gap-2 grid-cols-2 text-left">
                    <p
                      class="text-white sm:text-lg font-nexa font-normal mt-4 mr-1 ml-11 xl:ml-16"
                    >
                      % de Permuta
                    </p>

                    <div
                      class="md:pr-2 xl:mt-4 ml-3 sm:mr-14 mt-4 flex justify-start lg:justify-center"
                    >
                      <div
                        class="w-3/5 flex items-center justify-start space-x-5"
                      >
                        <button
                          @click="inputBarter('-')"
                          class="flex font-nexa text-agrogo-yellow-1 text-sm md:md:max-w-198 md:text-base xl:text-base"
                        >
                          -</button
                        ><input
                          id="barterPercentage"
                          placeholder="0"
                          @keyup="inputBarter(dataMoney.barterPercentage)"
                          v-model="dataMoney.barterPercentage"
                          class="input-standard w-20 xl:pl-4 md:max-w-110"
                          type="text"
                        /><button
                          @click="inputBarter('+')"
                          class="flex font-nexa text-agrogo-yellow-1 text-sm md:md:max-w-198 md:text-base xl:text-base"
                        >
                          +
                        </button>
                      </div>
                    </div>
                  </div>
                </div> -->
            <!-- </div> -->
            <!-- </div> -->
          </div>
        </div>
      </div>
      <!-- ACTIVAR O DESACTIVAR FORMULARIO CARACTERÍSTICAS  -->
      <div class="flex justify-center">
        <div
          class="w-full mx-3 mx-md:0 md:w-9/12 bg-agrogo-green-5 h-8 xl:h-14 flex justify-between items-center mt-7"
        >
          <div
            class="flex w-1/2 ml-2 justify-start cursor-pointer"
            @click.prevent="characteristicFarm"
          >
            <a class="flex w-full text-white text-lg md:text-2xl font-dinpro">
              <span
                class="icon-caracteristicas text-white text-lg md:text-2xl flex w-1/5 md:w-2/12 xl:w-1/12 justify-center items-center"
              ></span>
              <span class="flex bold-text">Características</span>
            </a>
          </div>
          <button
            class="h-8 xl:h-14 w-14 bg-agrogo-yellow-1 btn-primary rounded-none py-0"
            @click.prevent="characteristicFarm"
          >
            <span
              v-if="!stateFilter.characteristic"
              class="icon-Publicar text-black text-sm xl:text-2xl transform transition-all"
            ></span>
            <span
              v-else
              class="icon-menos text-black text-sm xl:text-2xl transform transition-all"
            ></span>
          </button>
        </div>
      </div>
      <!-- FORMULARIO CARACTERISTICAS -->
      <div v-if="stateFilter.characteristic" class="flex justify-center mt-3">
        <div class="w-full md:w-9/12">
          <div class="grid grid-cols-1 xl:grid-cols-2 gap-3">
            <div class="row-span-3">
              <div class="grid grid-cols-1 gap-3">
                <div class="row-span-3 bg-agrogo-green-5-filter-farm">
                  <div
                    class="flex flex-col md:pt-0 xl:pt-0 h-auto w-auto space-y-3 space-x-3 md:px-0 lg:px-0 ml-11 xl:ml-16"
                  >
                    <div class="flex items-center justify-between">
                      <a
                        class="font-nexa text-white text-sm text-left xl:text-base mt-2 ml-3"
                        >Uso de Suelo
                      </a>
                    </div>
                    <div class="grid grid-cols-1 xl:grid-cols-3 gap-x-2">
                      <div
                        v-for="item in dataLandUse"
                        :key="item.id"
                        class="flex items-center space-x-2 mb-5 cursor-pointer"
                      >
                        <input-checkbox
                          :item="item"
                          :typeCheck="'landUse'"
                          @addEditDelSelectFilter="addEditDelSelectFilter"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <!-- FILTRO DE TIPOGRAFIA-->
                <div class="bg-agrogo-green-5-filter-farm">
                  <div
                    class="flex flex-col md:pt-0 xl:pt-0 h-auto w-auto space-y-3 space-x-3 md:px-0 xl:px-0 mx-11 xl:ml-16 xl:mx-0"
                  >
                    <div class="flex items-center justify-between">
                      <a
                        class="font-nexa text-white text-sm text-left xl:text-base mt-2 ml-3"
                        >Topografía
                      </a>
                    </div>
                    <div class="grid grid-cols-2 xl:grid-cols-3 gap-x-2">
                      <div
                        v-for="item in dataTypography"
                        :key="item.id"
                        class="flex items-center space-x-2 mb-5"
                      >
                        <input-checkbox
                          :item="item"
                          :typeCheck="'typography'"
                          @addEditDelSelectFilter="addEditDelSelectFilter"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <!-- FILTRO DE TIPO DE SUELOS-->
                <div class="bg-agrogo-green-5-filter-farm">
                  <div
                    class="flex flex-col md:pt-0 xl:pt-0 h-auto w-auto space-y-3 space-x-3 md:px-0 xl:px-0 mx-11 xl:ml-16 xl:mx-0"
                  >
                    <div class="flex items-center justify-between">
                      <a
                        class="font-nexa text-white text-sm text-left xl:text-base mt-2 ml-3"
                        >Tipos de suelos
                      </a>
                    </div>
                    <div class="grid grid-cols-2 xl:grid-cols-3 gap-x-2">
                      <div
                        v-for="item in dataSoilTypes"
                        :key="item.id"
                        class="flex items-center space-x-2 mb-5"
                      >
                        <input-checkbox
                          :item="item"
                          :typeCheck="'soilTypes'"
                          @addEditDelSelectFilter="addEditDelSelectFilter"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <!-- FILTRO DE AGUAS-->
                <div class="row-span-3 bg-agrogo-green-5-filter-farm">
                  <div
                    class="flex flex-col md:pt-0 lg:pt-0 h-auto w-auto space-y-3 space-x-3 md:px-0 xl:px-0 mx-11 xl:ml-16 xl:mx-0"
                  >
                    <div class="flex items-center justify-between">
                      <a
                        class="font-nexa text-white text-sm text-left lg:text-base mt-2 ml-3"
                        >Aguas
                      </a>
                    </div>
                    <div class="grid grid-cols-2 xl:grid-cols-3 gap-x-2">
                      <div
                        v-for="item in dataWater"
                        :key="item.id"
                        class="flex items-center space-x-2 mb-5 text-blue-700"
                      >
                        <input-checkbox
                          :item="item"
                          :typeCheck="'water'"
                          @addEditDelSelectFilter="addEditDelSelectFilter"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- FILTRO DE PASTOS-->
            <div class="row-span-2 grid grid-cols-1">
              <div class="bg-agrogo-green-5-filter-farm">
                <div class="grid grid-cols-1 gap-y-3 md:grid-cols-2 xl:my-6">
                  <div class="flex items-center">
                    <p
                      class="text-white sm:text-lg font-nexa font-normal ml-11 xl:ml-16 text-left"
                    >
                      Pastos
                    </p>
                  </div>

                  <div
                    class="flex items-center justify-center md:justify-start"
                  >
                    <v-select
                      class="style-chooser3 style-chooser4 input-filter-farm-width sm:w-56"
                      label="name"
                      id="grasses"
                      :options="grasses"
                      v-model="selectGrasse"
                      :close-on-select="false"
                      multiple
                    >
                      <template #no-options>Sin opciones</template>
                    </v-select>
                  </div>
                </div>
              </div>
              <!-- FILTRO DE CULTIVOS-->
              <div class="bg-agrogo-green-5-filter-farm mt-3">
                <div class="grid gap-y-3 grid-cols-1 md:grid-cols-2 xl:my-6">
                  <div class="flex items-center">
                    <p
                      class="text-white sm:text-lg font-nexa font-normal ml-11 xl:ml-16 text-left"
                    >
                      Cultivos
                    </p>
                  </div>

                  <div
                    class="flex items-center justify-center md:justify-start"
                  >
                    <v-select
                      class="style-chooser3 style-chooser4 input-filter-farm-width sm:w-56"
                      label="name"
                      id="crops"
                      :options="crops"
                      v-model="selectCrop"
                      :close-on-select="false"
                      multiple
                    >
                      <template #no-options>Sin opciones</template>
                    </v-select>
                  </div>
                </div>
              </div>
              <!-- FILTRO DE ALTURA (MSCM)-->
              <div
                class="bg-agrogo-green-5-filter-farm row-span-2 mt-14 xl:mt-3"
              >
                <div class="grid xl:gap-2 grid-cols-1 xl:grid-cols-2">
                  <div class="flex">
                    <p
                      class="text-white sm:text-lg font-nexa font-normal mt-0 xl:mt-4 mr-1 ml-11 xl:ml-16 text-left"
                    >
                      Altura
                      <span class="text-agrogo-gray-1 text-xs lg:text-base"
                        >(msnm)</span
                      >
                    </p>
                  </div>
                  <div class="pt-2 grid grid-cols-1 xl:grid-cols-2 xl:mr-10">
                    <div
                      class="mt-2 grid grid-cols-2 xl:grid-cols-1 gap-x-2 mx-11 xl:ml-3 xl:mx-0"
                    >
                      <p
                        class="text-white font-nexa text-sm sm:text-base text-left xl:text-center mr-2 xl:mr-0 ml-6 xl:ml-0"
                      >
                        Desde
                      </p>
                      <input
                        id="heightFrom"
                        placeholder="0"
                        v-model="heightFrom"
                        @keyup="
                          addEditDelSelectFilter(
                            null,
                            'heightFrom',
                            heightFrom,
                            'heightFrom'
                          )
                        "
                        @blur="validationHeightMin"
                        type="text"
                        class="input-standard input-filter-farm-width xl:ml-0"
                      />
                    </div>
                    <div
                      class="mt-2 grid grid-cols-2 xl:grid-cols-1 gap-x-2 mb-3 mx-11 xl:ml-3 xl:mx-0 xl:mb-0"
                    >
                      <p
                        class="text-white font-nexa text-sm sm:text-base text-left xl:text-center mr-2 xl:mr-0 ml-6 xl:ml-0"
                      >
                        Hasta
                      </p>
                      <input
                        id="heightUntil"
                        v-model="heightUntil"
                        placeholder="0"
                        @keyup="
                          addEditDelSelectFilter(
                            null,
                            'heightUntil',
                            heightUntil,
                            'heightUntil'
                          )
                        "
                        @blur="validationHeightMax"
                        type="text"
                        class="input-standard input-filter-farm-width xl:ml-0"
                      />
                    </div>
                  </div>
                </div>
                <div class="mt-1 mb-1">
                  <p
                    class="text-xs md:text-base lg:text-base text-agrogo-yellow-2 font-nexa block text-center"
                    v-if="isValidationMin"
                  >
                    La altura Desde no puede ser superior a la Hasta.
                  </p>
                  <p
                    class="text-xs md:text-base lg:text-base text-agrogo-yellow-2 font-nexa block text-center"
                    v-else-if="isValidationMax"
                  >
                    La altura Hasta no puede ser inferior a la Desde.
                  </p>
                  <p
                    class="text-xs md:text-base lg:text-base text-agrogo-yellow-2 font-nexa block text-center"
                    v-else
                  >
                    &nbsp;
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- BOTONES APLICAR BORRAR FILTROS -->
    <div
      class="flex items-center justify-center bg-agrogo-yellow-1 h-16 sticky bottom-0"
    >
      <!-- <p
        class="font-nexa text-agrogo-green-2 font-black text-base md:text-2xl text-left ml-3 md:ml-0"
      >
        <span class="font-dinpro font-normal">Encuentra la Finca </span>Perfecta
        para ti
      </p> -->
      <button
        type="button"
        class="btn-primary flex items-center justify-center h-9 bg-agrogo-green-2 rounded-xl font-nexa text-sm text-agrogo-yellow-1 w-32 md:text-base md:ml-5"
        :disabled="
          filterIsDisabled ||
          isValidationPriceHect1 ||
          isValidationPriceHect2 ||
          isValidationPriceTotal1 ||
          isValidationPriceTotal2 ||
          isValidationArea1 ||
          isValidationArea2 ||
          isValidationMin ||
          isValidationMax ||
          isValidationDistanceMin ||
          isValidationDistanceMax
        "
        @click.prevent="applyFilter()"
      >
        Aplicar
        <span class="icon-arrow text-sm pl-2 md:text-lg"></span>
      </button>
      <button
        @click="delAllFilter"
        type="button"
        class="btn-primary flex items-center justify-center h-9 bg-agrogo-green-2 rounded-xl font-nexa text-sm text-agrogo-yellow-1 w-32 md:text-base ml-1 md:ml-5 mr-3 md:mr-0"
      >
        Borrar
        <span class="icon-refresh text-3xs pl-2 md:text-xs"></span>
      </button>
    </div>
  </div>
</template>
<script>
import { generateHash } from "@/utils";
// components
import InputCheckbox from "./InputCheckbox";

export default {
  inject: ["provider"],
  name: "FarmFilter",
  emits: [
    "applyFilter",
    "deleteAllFilter",
    "fillFilterSuccess",
    "updateCategoryId",
  ],
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    search: {
      type: String,
      default: null,
    },
    searchCategoryId: {
      type: String,
      default: null,
    },
  },
  components: {
    InputCheckbox,
  },
  data() {
    return {
      repository: this.provider.publishFarmRepository,
      listOfFarmRepository: this.provider.listOfFarmRepository,
      stateFilter: [
        { typeFarm: false },
        { location: false },
        { money: false },
        { characteristic: false },
      ],
      selected: [],
      municipalities: [],
      departments: [],
      dataSoilTypes: [],
      selectedLandUse: [],
      selectedTypography: [],
      selectedSoilType: [],
      selectedWater: [],
      dataLandUse: [],
      dataTypography: [],
      dataWater: [],
      grasses: [],
      crops: [],
      filterSelect: [],
      options: [
        { text: "Si", value: "1" },
        { text: "No", value: "0" },
      ],
      dataMoney: {
        areaUntil: 0,
        areaFrom: 0,
        priceHectareFrom: "$ 0",
        priceHectareUntil: "$ 0",
        priceTotalFrom: "$ 0",
        priceTotalUntil: "$ 0",
        barterPercentage: "$ 0",
      },
      category: null,
      selectedDepart: [],
      selectedMunicipality: [],
      heightFrom: null,
      heightUntil: null,
      barter: null,
      selectGrasse: [],
      selectCrop: [],
      isBusy: false,
      filterIsDisabled: true,
      isPercentage: false,
      isValidationPriceHect1: false,
      isValidationPriceHect2: false,
      isValidationPriceTotal1: false,
      isValidationPriceTotal2: false,
      isValidationArea1: false,
      isValidationArea2: false,
      isValidationMin: false,
      isValidationMax: false,
      distanceFrom: null,
      isValidationDistanceMin: false,
      distanceUntil: null,
      isValidationDistanceMax: false,
    };
  },
  watch: {
    selectedMunicipality() {
      const me = this;
      if (me.selectedMunicipality) {
        let newArray = [];

        // sacar los municipios de otro departamento
        const municipies = me.filterSelect.filter(
          (el) => el.departId && el.departId !== me.selectedDepart.id
        );

        newArray = me.filterSelect.filter(
          (el) => el.typeFilter !== "filterMunicipie"
        );

        me.filterSelect = newArray;
        if (municipies.length > 0) {
          me.filterSelect = [...me.filterSelect, ...municipies];
        }

        me.selectedMunicipality.forEach((el) => {
          me.addEditDelSelectFilter(
            el.id,
            "filterMunicipie",
            el.name,
            "municipies",
            me.selectedDepart.id
          );
        });
      } else {
        if (me.filterSelect.length > 0) {
          const municipie = me.filterSelect.find(
            (el) => el.selectId == "municipies"
          );
          if (!municipie)
            me.deleteSelectFilter(null, "filterMunicipie", "municipies");
        } else {
          me.deleteSelectFilter(null, "filterMunicipie", "municipies");
        }
      }
      // me.selectedMunicipality = null;
    },
    selectGrasse() {
      const me = this;
      if (me.selectGrasse) {
        let newArray = [];

        newArray = me.filterSelect.filter(
          (el) => el.typeFilter !== "filterGrasses"
        );

        me.filterSelect = newArray;
        me.selectGrasse.forEach((el) => {
          me.addEditDelSelectFilter(el.id, "filterGrasses", el.name, "grasses");
        });
      } else {
        if (me.filterSelect.length > 0) {
          const grasse = me.filterSelect.find((el) => el.selectId == "grasses");

          if (!grasse) me.deleteSelectFilter(null, "filterGrasses", "grasses");
        } else {
          me.deleteSelectFilter(null, "filterGrasses", "grasses");
        }
      }
    },
    selectCrop() {
      const me = this;
      if (me.selectCrop) {
        let newArray = [];

        newArray = me.filterSelect.filter(
          (el) => el.typeFilter !== "filterCrops"
        );

        me.filterSelect = newArray;
        me.selectCrop.forEach((el) => {
          me.addEditDelSelectFilter(el.id, "filterCrops", el.name, "crops");
        });
      } else {
        if (me.filterSelect.length > 0) {
          const crop = me.filterSelect.find((el) => el.selectId == "crops");

          if (!crop) me.deleteSelectFilter(null, "filterCrops", "crops");
        } else {
          me.deleteSelectFilter(null, "filterCrops", "crops");
        }
      }
    },
    search() {
      const me = this;
      if (me.search && me.category) me.loadSelected();
    },
  },
  methods: {
    /**
     * Query the agrogo api and obtain the information of the departaments.
     * @returns  {void}
     */
    async loadDepartments() {
      const me = this;
      try {
        const data_aditional = await generateHash();
        const {
          data: { data },
        } = await me.repository.getAllDeparts(data_aditional);
        me.departments = data;
      } catch (error) {
        // console.log(error);
      }
    },
    /**
     * Query the agrogo api and obtain the information of the municipies.
     * @param {number} id get the id of the departament
     * @returns  {void}
     */
    async loadMunicipies() {
      const me = this;
      me.resetSelect();
      if (me.selectedDepart) {
        let newArray = [];

        newArray = me.filterSelect.filter(
          (el) => el.typeFilter !== "filtroDepartamento"
        );

        me.filterSelect = newArray;
        me.selectedDepart.forEach((el) => {
          me.addEditDelSelectFilter(
            el.id,
            "filtroDepartamento",
            el.name,
            "departments"
          );
          me.municipalities = [...me.municipalities, ...el.municipalities];
        });
        me.municipalities.sort((a, b) => a.name.localeCompare(b.name));
      } else {
        if (me.filterSelect.length > 0) {
          const depart = me.filterSelect.find(
            (el) => el.selectId == "departments"
          );

          if (!depart)
            me.deleteSelectFilter(null, "filtroDepartamento", "departments");
        } else {
          me.deleteSelectFilter(null, "filtroDepartamento", "departments");
        }
      }
    },
    /**
     * Query the agrogo api and obtain the information of the categories.
     * @returns  {void}
     */
    async loadCategories() {
      const me = this;
      try {
        me.isBusy = true;
        const data_aditional = await generateHash();
        const {
          data: { data },
        } = await me.repository.getAll("1", data_aditional);
        data.subcategories.forEach((el) => {
          el.isActive = false;
        });
        me.category = data;
      } catch (error) {
        // console.log(error);
      } finally {
        me.isBusy = false;
      }
    },
    /**
     * enable or disable the type farm form
     * @returns {void}
     */
    typeFarm() {
      const me = this;
      me.stateFilter.typeFarm = me.stateFilter.typeFarm ? false : true;
    },
    /**
     * enable or disable the location form
     * @returns {void}
     */
    locationFarm() {
      const me = this;
      me.stateFilter.location = me.stateFilter.location ? false : true;
    },
    /**
     * enable or disable the monay form
     * @returns {void}
     */
    moneyFarm() {
      const me = this;
      me.stateFilter.money = me.stateFilter.money ? false : true;
    },
    /**
     * enable or disable the feature form
     * @returns {void}
     */
    characteristicFarm() {
      const me = this;
      me.stateFilter.characteristic = me.stateFilter.characteristic
        ? false
        : true;
    },
    /**
     * Change the styles of the item selected by the user in the farm type form.
     * @param {String} id get the id of the category
     * @param {String} name get the name of the category
     * @returns {void}
     */
    addRemove(id, name = null) {
      const me = this;
      me.category.subcategories.forEach((el) => {
        if (el.id == id) {
          if (el.isActive) {
            el.isActive = false;
            me.selected.forEach((x, index) => {
              if (x == id) me.selected.splice(index, 1);
            });
          } else {
            el.isActive = true;
            me.selected.push(id);
          }
        }
      });
      me.addEditDelSelectFilter(
        id,
        "filterTypeFarm",
        name,
        "card-farm-filter" + id
      );
    },
    /**
     * formats input properties with mil dots
     * @param {String} money  receives a string that allows validating the type of filter that I know is going to format and likewise assign the corresponding data to the variable
     * @returns  {void}
     */
    formatInput(typeFilter) {
      const me = this;
      if (typeFilter == "areaFrom") {
        const formatter = new Intl.NumberFormat("es-CO", {
          minimumFractionDigits: 0,
        });
        const area10 = me.dataMoney.areaFrom.split(",");
        if (area10.length > 2) {
          me.dataMoney.areaFrom = `${area10[0]}${
            area10[1] !== "" ? `,${area10[1]}` : ","
          }`;
        }
        let area = me.dataMoney.areaFrom.replace(/[^\d,.]/g, "");
        let area2 = me.dataMoney.areaFrom.replace(/[^\d,.]/g, "");
        let attribute = 0;
        let isValidate = true;
        if (me.dataMoney.areaFrom.split(".")[1]) {
          area = me.dataMoney.areaFrom
            .split(".")
            .join("")
            .replace(/[^\d,.]/g, "");
          attribute = formatter.format(area);
        }

        if (me.dataMoney.areaFrom.split(",")[1]) {
          if (me.dataMoney.areaFrom.split(".")[1]) {
            const area3 = me.dataMoney.areaFrom
              .split(",")[0]
              .split(".")
              .join("")
              .replace(/[^\d,.]/g, "");
            area2 = formatter.format(area3);
          } else {
            const area3 = me.dataMoney.areaFrom
              .split(",")[0]
              .replace(/[^\d,.]/g, "");
            area2 = formatter.format(area3);
          }
          isValidate = false;
          let t = area.toString();
          let regex = /(\d*.\d{0,1})/;
          const attribute2 = t.match(regex)[0];
          attribute = `${area2.split(",")[0]},${attribute2.split(",")[1]}`;
        } else {
          area = me.dataMoney.areaFrom
            .split(".")
            .join("")
            .replace(/[^\d,.]/g, "");
          attribute = formatter.format(area);
        }
        if (isNaN(attribute.split(".").join("")) && isValidate)
          attribute = area2;
        me.dataMoney.areaFrom = attribute;
      }
      if (typeFilter == "areaUntil") {
        const formatter = new Intl.NumberFormat("es-CO", {
          minimumFractionDigits: 0,
        });
        const area10 = me.dataMoney.areaUntil.split(",");
        if (area10.length > 2) {
          me.dataMoney.areaUntil = `${area10[0]}${
            area10[1] !== "" ? `,${area10[1]}` : ","
          }`;
        }
        let area = me.dataMoney.areaUntil.replace(/[^\d,.]/g, "");
        let area2 = me.dataMoney.areaUntil.replace(/[^\d,.]/g, "");
        let attribute = 0;
        let isValidate = true;

        if (me.dataMoney.areaUntil.split(".")[1]) {
          area = me.dataMoney.areaUntil
            .split(".")
            .join("")
            .replace(/[^\d,.]/g, "");
          attribute = formatter.format(area);
        }

        if (me.dataMoney.areaUntil.split(",")[1]) {
          if (me.dataMoney.areaUntil.split(".")[1]) {
            const area3 = me.dataMoney.areaUntil
              .split(",")[0]
              .split(".")
              .join("")
              .replace(/[^\d,.]/g, "");
            area2 = formatter.format(area3);
          } else {
            const area3 = me.dataMoney.areaUntil
              .split(",")[0]
              .replace(/[^\d,.]/g, "");
            area2 = formatter.format(area3);
          }
          isValidate = false;
          let t = area.toString();
          let regex = /(\d*.\d{0,1})/;
          const attribute2 = t.match(regex)[0];
          attribute = `${area2.split(",")[0]},${attribute2.split(",")[1]}`;
        } else {
          area = me.dataMoney.areaUntil
            .split(".")
            .join("")
            .replace(/[^\d,.]/g, "");
          attribute = formatter.format(area);
        }

        if (isNaN(attribute.split(".").join("")) && isValidate)
          attribute = area2;
        me.dataMoney.areaUntil = attribute;
      }
      if (typeFilter == "priceHectareFrom") {
        if (me.dataMoney.priceHectareFrom.split("$")[1]) {
          me.dataMoney.priceHectareFrom = me.dataMoney.priceHectareFrom
            .split("$")[1]
            .split(".")
            .join("")
            .replace(/\D/g, "");

          if (isNaN(me.dataMoney.priceHectareFrom))
            me.dataMoney.priceHectareFrom = 0;
        } else {
          if (isNaN(me.dataMoney.priceHectareFrom))
            me.dataMoney.priceHectareFrom = 0;
        }

        me.dataMoney.priceHectareFrom = me.formatThousand(
          me.dataMoney.priceHectareFrom
        );
      }
      if (typeFilter == "priceHectareUntil") {
        if (me.dataMoney.priceHectareUntil.split("$")[1]) {
          me.dataMoney.priceHectareUntil = me.dataMoney.priceHectareUntil
            .split("$")[1]
            .split(".")
            .join("")
            .replace(/\D/g, "");

          if (isNaN(me.dataMoney.priceHectareUntil))
            me.dataMoney.priceHectareUntil = 0;
        } else {
          if (isNaN(me.dataMoney.priceHectareUntil))
            me.dataMoney.priceHectareUntil = 0;
        }

        me.dataMoney.priceHectareUntil = me.formatThousand(
          me.dataMoney.priceHectareUntil
        );
      }
      if (typeFilter == "priceTotalFrom") {
        if (me.dataMoney.priceTotalFrom.split("$")[1]) {
          me.dataMoney.priceTotalFrom = me.dataMoney.priceTotalFrom
            .split("$")[1]
            .split(".")
            .join("")
            .replace(/\D/g, "");

          if (isNaN(me.dataMoney.priceTotalFrom))
            me.dataMoney.priceTotalFrom = 0;
        } else {
          if (isNaN(me.dataMoney.priceTotalFrom))
            me.dataMoney.priceTotalFrom = 0;
        }

        me.dataMoney.priceTotalFrom = me.formatThousand(
          me.dataMoney.priceTotalFrom
        );
      }
      if (typeFilter == "priceTotalUntil") {
        if (me.dataMoney.priceTotalUntil.split("$")[1]) {
          me.dataMoney.priceTotalUntil = me.dataMoney.priceTotalUntil
            .split("$")[1]
            .split(".")
            .join("")
            .replace(/\D/g, "");

          if (isNaN(me.dataMoney.priceTotalUntil))
            me.dataMoney.priceTotalUntil = 0;
        } else {
          if (isNaN(me.dataMoney.priceTotalUntil))
            me.dataMoney.priceTotalUntil = 0;
        }

        me.dataMoney.priceTotalUntil = me.formatThousand(
          me.dataMoney.priceTotalUntil
        );
      }
      if (typeFilter == "heightFrom") {
        const formatterPeso = new Intl.NumberFormat("es-CO", {
          minimumFractionDigits: 0,
        });
        me.heightFrom = me.heightFrom.split(".").join("");
        if (isNaN(me.heightFrom)) me.heightFrom = 0;

        me.heightFrom = formatterPeso.format(me.heightFrom);
      }
      if (typeFilter == "heightUntil") {
        const formatterPeso = new Intl.NumberFormat("es-CO", {
          minimumFractionDigits: 0,
        });
        me.heightUntil = me.heightUntil.split(".").join("");
        if (isNaN(me.heightUntil)) me.heightUntil = 0;

        me.heightUntil = formatterPeso.format(me.heightUntil);
      }
      if (typeFilter == "distanceFrom") {
        const formatterPeso = new Intl.NumberFormat("es-CO", {
          minimumFractionDigits: 0,
        });
        me.distanceFrom = me.distanceFrom.split(".").join("");
        if (isNaN(me.distanceFrom)) me.distanceFrom = 0;

        me.distanceFrom = formatterPeso.format(me.distanceFrom);
      }
      if (typeFilter == "distanceUntil") {
        const formatterPeso = new Intl.NumberFormat("es-CO", {
          minimumFractionDigits: 0,
        });
        me.distanceUntil = me.distanceUntil.split(".").join("");
        if (isNaN(me.distanceUntil)) me.distanceUntil = 0;

        me.distanceUntil = formatterPeso.format(me.distanceUntil);
      }
    },

    /**
     * Method that formats the numeric data that it receives as a parameter and assigns it the $ sign and the corresponding text depending on whether it is in the thousands, million or millions. Once done, the formatted string is returned.
     * @param {String} money  receives a string to carry out the respective formatting to be displayed in the list of selected filters
     * @returns  {void}
     */
    formatMoney(money) {
      const me = this;
      if (money.split("$")[1]) {
        money = Number(
          money.split("$")[1].split(".").join("").replace(/\D/g, "")
        );
      } else {
        money = Number(
          money.split(".")
            ? money.split(".").join("").replace(/\D/g, "")
            : money
        );
      }

      if (money >= 0 && money <= 99999) {
        money = me.formatThousand(money);
      }
      if (money >= 100000 && money <= 999999) {
        money = me.formatThousand(Number(money / 1000)) + " mil";
      }
      if (money == 1000000) {
        money = me.formatThousand(Number(money / 1000000)) + " millón";
      }
      if (money > 1000000 && money <= 999999999) {
        money = me.formatThousand(Number(money / 1000000)) + " millones";
      }
      if (money == 1000000000) {
        money = me.formatThousand(Number(money / 1000000000)) + "Billón";
      }
      if (money > 1000000000 && money <= 999999999999) {
        money = me.formatThousand(Number(money / 1000000000)) + "Billones";
      }
      if (money == 1000000000000) {
        money = me.formatThousand(Number(money / 1000000000000)) + "Trillón";
      }
      if (money > 1000000000000 && money <= 999999999999999) {
        money = me.formatThousand(Number(money / 1000000000000)) + "Trillones";
      }
      return money;
    },

    /**
     * Method that assigns, deletes or edits the filters fields filled in the filterSelect array attribute
     * @returns  {void}
     */
    addEditDelSelectFilter(
      id = null,
      typeFilter,
      text = null,
      selectId = null
    ) {
      const me = this;
      //FORMATEA CON PUNTOS DE MIL LOS INPUTS
      me.formatInput(typeFilter);

      let validation_creation = true;
      if (me.filterSelect.length > 0) {
        //SI EL ELEMENTO TIENE UN ID POR TANTO ES DE SELECCION MULTIPLE
        if (id != null) {
          me.filterSelect.forEach((element, index) => {
            //SI EL ELEMENTO ES EXISTE DEBE SER ELIMINADO YA QUE DEJÓ DE ESTAR SELECCIONADO
            if (
              element.id == id &&
              element.typeFilter == typeFilter &&
              element.selectId == selectId
            ) {
              validation_creation = false;
              me.filterSelect.splice(index, 1);
              // Add delete
              me.addDeleteSelectCheckbox(id, typeFilter, false);
            }
          });
        } else {
          //VALIDA SI EL TEXT ENVIADO TIENE UN VALOR O SI NO SERÁ ELIMINADO
          if (text > 0 || text.length > 0) {
            me.filterSelect.forEach((element, index) => {
              //SI EL ELEMENTO ES EXISTE DEBE SER EDITADO
              if (
                element.id == id &&
                element.typeFilter == typeFilter &&
                element.selectId == selectId
              ) {
                //SI EL ELMENTO ENVIADO ES DE TIPO PRECIO
                // if (
                //   typeFilter == "priceHectareFrom" ||
                //   typeFilter == "priceHectareUntil" ||
                //   typeFilter == "priceTotalFrom" ||
                //   typeFilter == "priceTotalUntil"
                // ) {
                //   text = me.formatMoney(text);
                // }

                if (typeFilter == "priceHectareFrom") {
                  text = `Precio hect. desde ${me.formatMoney(text)}`;
                }

                if (typeFilter == "priceHectareUntil") {
                  text = `Precio hect. hasta ${me.formatMoney(text)}`;
                }

                if (typeFilter == "priceTotalFrom") {
                  text = `Precio total desde ${me.formatMoney(text)}`;
                }

                if (typeFilter == "priceTotalUntil") {
                  text = `Precio total hasta ${me.formatMoney(text)}`;
                }

                //SI EL ELMENTO ENVIADO ES DE AREA UNTIL O FROM
                if (typeFilter == "areaFrom") {
                  text = `Area desde ${me.dataMoney.areaFrom}ha`;
                }
                if (typeFilter == "areaUntil") {
                  text = `Area hasta ${me.dataMoney.areaUntil}ha`;
                }
                //SI EL ELMENTO ENVIADO ES DE HEIGHT UNTIL O FROM
                if (typeFilter == "heightFrom") {
                  text = `Altura desde ${me.heightFrom}msnm`;
                }
                if (typeFilter == "heightUntil") {
                  text = `Altura hasta ${me.heightUntil}msnm`;
                }
                if (typeFilter == "distanceFrom") {
                  text = `Distacia desde ${me.distanceFrom}Km`;
                }
                if (typeFilter == "distanceUntil") {
                  text = `Distacia hasta ${me.distanceUntil}Km`;
                }
                validation_creation = false;
                me.filterSelect[index].text = text;
              }
            });
          } else {
            validation_creation = false;
            me.deleteSelectFilter(null, typeFilter, selectId);
          }
        }
      }

      if (validation_creation && text) {
        //SI EL ELMENTO ENVIADO ES DE AREA UNTIL O FROM
        if (typeFilter == "areaFrom") {
          text = `Area desde ${me.dataMoney.areaFrom}ha`;
        }
        if (typeFilter == "areaUntil") {
          text = `Area hasta ${me.dataMoney.areaUntil}ha`;
        }
        //SI EL ELMENTO ENVIADO ES DE HEIGHT UNTIL O FROM
        if (typeFilter == "heightFrom") {
          text = `Altura desde ${me.heightFrom}msnm`;
        }
        if (typeFilter == "heightUntil") {
          text = `Altura hasta ${me.heightUntil}msnm`;
        }
        if (typeFilter == "distanceFrom") {
          text = `Distacia desde ${me.distanceFrom}Km`;
        }
        if (typeFilter == "distanceUntil") {
          text = `Distacia hasta ${me.distanceUntil}Km`;
        }
        if (typeFilter == "priceHectareFrom") {
          text = `Precio hect. desde ${me.formatMoney(text)}`;
        }
        if (typeFilter == "priceHectareUntil") {
          text = `Precio hect. hasta ${me.formatMoney(text)}`;
        }
        if (typeFilter == "priceTotalFrom") {
          text = `Precio total desde ${me.formatMoney(text)}`;
        }
        if (typeFilter == "priceTotalUntil") {
          text = `Precio total hasta ${me.formatMoney(text)}`;
        }

        me.filterSelect.push({
          id: id,
          typeFilter: typeFilter,
          text: text,
          selectId: selectId,
        });
        // Add checkbox
        me.addDeleteSelectCheckbox(id, typeFilter, true);
      }

      if (me.dataMoney.priceHectareFrom == 0) {
        me.filterSelect.forEach((el, index) => {
          if (el.typeFilter == "priceHectareFrom") {
            me.filterSelect.splice(index, 1);
          }
        });
      }

      if (me.dataMoney.priceHectareUntil == 0) {
        me.filterSelect.forEach((el, index) => {
          if (el.typeFilter == "priceHectareUntil") {
            me.filterSelect.splice(index, 1);
          }
        });
      }

      if (me.dataMoney.priceTotalFrom == 0) {
        me.filterSelect.forEach((el, index) => {
          if (el.typeFilter == "priceTotalFrom") {
            me.filterSelect.splice(index, 1);
          }
        });
      }

      if (me.dataMoney.priceTotalUntil == 0) {
        me.filterSelect.forEach((el, index) => {
          if (el.typeFilter == "priceTotalUntil") {
            me.filterSelect.splice(index, 1);
          }
        });
      }

      if (me.heightFrom == 0) {
        me.filterSelect.forEach((el, index) => {
          if (el.typeFilter == "heightFrom") {
            me.filterSelect.splice(index, 1);
          }
        });
      }

      if (me.heightUntil == 0) {
        me.filterSelect.forEach((el, index) => {
          if (el.typeFilter == "heightUntil") {
            me.filterSelect.splice(index, 1);
          }
        });
      }

      if (me.distanceFrom == 0) {
        me.filterSelect.forEach((el, index) => {
          if (el.typeFilter == "distanceFrom") {
            me.filterSelect.splice(index, 1);
          }
        });
      }

      if (me.distanceUntil == 0) {
        me.filterSelect.forEach((el, index) => {
          if (el.typeFilter == "distanceUntil") {
            me.filterSelect.splice(index, 1);
          }
        });
      }

      //HABILITA O DESABILITA LOS BOTONES DE FILTRADO Y LIMPIAR
      if (me.filterSelect.length > 0) {
        me.filterIsDisabled = false;
      } else {
        me.filterIsDisabled = true;
      }
    },
    /**
     * Validate the % input of the form if it is less than 0 or greater than 100
     * @returns  {void}
     */
    inputBarter(value) {
      const me = this;
      if (value == "-") {
        value = me.dataMoney.barterPercentage - 1;
      }
      if (value == "+") {
        value = me.dataMoney.barterPercentage + 1;
      }
      if (value >= 0 && value <= 100) {
        me.dataMoney.barterPercentage = value;
        me.addEditDelSelectFilter(
          null,
          "barterPercentage",
          me.dataMoney.barterPercentage + " %",
          "barterPercentage"
        );
      } else {
        me.dataMoney.barterPercentage = null;
        me.deleteSelectFilter(null, "barterPercentage", "barterPercentage");
        me.$swal({
          icon: "info",
          text: "El porcentaje permitido es de 0% al 100%",
        });
      }
    },
    /**
     * Format thousand points
     * @returns  {void}
     */
    formatThousand(number) {
      const formatterPeso = new Intl.NumberFormat("es-CO", {
        style: "currency",
        currency: "COP",
        minimumFractionDigits: 0,
      });
      number = formatterPeso.format(number);
      return number;
    },
    //ELIMINA LOS ELEMENTOS DE FILTRADO
    deleteSelectFilter(id = null, typeFilter, selectId = null) {
      const me = this;
      if (me.filterSelect.length > 0) {
        //SI EL ELEMENTO TIENE UN ID POR TANTO ES DE SELECCION MULTIPLE
        if (id != null) {
          me.filterSelect.forEach((element, index) => {
            if (element.typeFilter == typeFilter && element.id == id) {
              //SI LOS ELEMENTOS MULTIPLES SON DE TIPO DE FINCA
              if (typeFilter == "filterTypeFarm") {
                me.category.subcategories.forEach((el) => {
                  if (el.id == id) {
                    if (el.isActive) {
                      el.isActive = false;
                      me.selected.forEach((x, index) => {
                        if (x == id) me.selected.splice(index, 1);
                      });
                    } else {
                      el.isActive = true;
                      me.selected.push(id);
                    }
                  }
                });
              } else {
                //ELIMINAR SELECCIÓN DE CHECKBOX USO DE SUELOS
                if (typeFilter == "landUse") {
                  me.selectedLandUse.forEach((el, index) => {
                    if (el == id) {
                      me.selectedLandUse.splice(index, 1);
                      me.dataLandUse.forEach((y) => {
                        if (y.id == id) {
                          y.state = false;
                        }
                      });
                    }
                  });
                }
                //ELIMINAR SELECCIÓN DE CHECKBOX TOPOGRAFIA
                if (typeFilter == "typography") {
                  me.selectedTypography.forEach((el, index) => {
                    if (el == id) {
                      me.selectedTypography.splice(index, 1);
                      me.dataTypography.forEach((y) => {
                        if (y.id == id) {
                          y.state = false;
                        }
                      });
                    }
                  });
                }
                //ELIMINAR SELECCIÓN DE CHECKBOX TIPO DE SUELOS
                if (typeFilter == "soilTypes") {
                  me.selectedSoilType.forEach((el, index) => {
                    if (el == id) {
                      me.selectedSoilType.splice(index, 1);
                      me.dataSoilTypes.forEach((y) => {
                        if (y.id == id) {
                          y.state = false;
                        }
                      });
                    }
                  });
                }
                //ELIMINAR SELECCIÓN DE CHECKBOX AGUAS
                if (typeFilter == "water") {
                  me.selectedWater.forEach((el, index) => {
                    if (el == id) {
                      me.selectedWater.splice(index, 1);
                      me.dataWater.forEach((y) => {
                        if (y.id == id) {
                          y.state = false;
                        }
                      });
                    }
                  });
                }
              }
              me.deleteItemSelectMultiple(element);
              me.filterSelect.splice(index, 1);
            }
          });
        } else {
          //SI EL ELEMENTO ES UNICO
          me.filterSelect.forEach((element, index) => {
            if (
              element.typeFilter == typeFilter &&
              element.selectId == selectId
            ) {
              //ELIMINAR VALOR INPUT MEDIANTE EL ID
              if (selectId == "areaFrom") {
                me.dataMoney.areaFrom = "";
              } else if (selectId == "areaUntil") {
                me.dataMoney.areaUntil = "";
              } else if (selectId == "priceHectareFrom") {
                me.dataMoney.priceHectareFrom = "$ 0";
              } else if (selectId == "priceHectareUntil") {
                me.dataMoney.priceHectareUntil = "$ 0";
              } else if (selectId == "priceTotalFrom") {
                me.dataMoney.priceTotalFrom = "$ 0";
              } else if (selectId == "priceTotalUntil") {
                me.dataMoney.priceTotalUntil = "$ 0";
              } else if (selectId == "heightFrom") {
                me.heightFrom = "";
              } else if (selectId == "heightUntil") {
                me.heightUntil = "";
              } else if (selectId == "distanceFrom") {
                me.distanceFrom = "";
              } else if (selectId == "distanceUntil") {
                me.distanceUntil = "";
              }
              me.filterSelect.splice(index, 1);
            }
          });
        }
        //HABILITA O DESABILITA LOS BOTONES DE FILTRADO Y LIMPIAR
        if (me.filterSelect.length > 0) {
          me.filterIsDisabled = false;
        } else {
          me.filterIsDisabled = true;
        }

        //Vaciar select de departamentos
        const depart = me.filterSelect.find(
          (el) => el.selectId == "departments"
        );
        if (!depart) me.selectedDepart = [];
        //Vaciar select de municipios
        const municip = me.filterSelect.find(
          (el) => el.selectId == "municipies"
        );
        if (!municip) me.selectedMunicipality = [];
      }

      if (me.filterSelect.length == 0) {
        me.delAllFilter();
      }
    },
    /**
     * Delete the data of the selected municipality and clean the list of municipalities
     * @returns  {void}
     */
    resetSelect() {
      const me = this;
      me.municipalities = [];
      // me.selectedMunicipality = [];
    },
    deleteItemSelectMultiple(item) {
      const me = this;
      if (item.selectId == "municipies") {
        if (me.selectedMunicipality) {
          me.selectedMunicipality.forEach((el, index) => {
            if (item.id == el.id) me.selectedMunicipality.splice(index, 1);
          });
        }
      } else if (item.selectId == "grasses") {
        if (me.selectGrasse) {
          me.selectGrasse.forEach((el, index) => {
            if (item.id == el.id) me.selectGrasse.splice(index, 1);
          });
        }
      } else if (item.selectId == "crops") {
        if (me.selectCrop) {
          me.selectCrop.forEach((el, index) => {
            if (item.id == el.id) me.selectCrop.splice(index, 1);
          });
        }
      } else if (item.selectId == "departments") {
        if (me.selectedDepart) {
          me.selectedDepart.forEach((el, index) => {
            if (item.id == el.id) me.selectedDepart.splice(index, 1);
          });
        }
      }

      // Llenar nuevamente el select de municipios
      me.resetSelect();
      me.selectedDepart.forEach((el) => {
        me.municipalities = [...me.municipalities, ...el.municipalities];
      });
      me.municipalities.sort((a, b) => a.name.localeCompare(b.name));
    },
    /**
     * Reset all styles of category type cards
     * @returns  {void}
     */
    removeStylesAllCard() {
      const me = this;
      me.category.subcategories.forEach((element) => {
        var card_id = document.getElementById("card-farm-filter" + element.id);
        if (card_id) {
          if (card_id.classList[1]) {
            card_id.classList.remove("active-card-farm-filter");
          }
        }
      });
    },
    /**
     * Remove all filters from view
     * @returns  {void}
     */
    delAllFilter() {
      const me = this;
      me.clearFilter();
      // delete all filters
      me.$router.push({ query: null });
      me.$emit("deleteAllFilter");
    },
    clearFilter() {
      const me = this;
      me.removeStylesAllCard();
      me.resetSelect();
      me.dataMoney = {
        areaUntil: null,
        areaFrom: null,
        priceHectareFrom: "$ 0",
        priceHectareUntil: "$ 0",
        priceTotalFrom: "$ 0",
        priceTotalUntil: "$ 0",
        barterPercentage: 0,
      };
      me.filterSelect = [];
      me.selectedDepart = [];
      me.selectGrasse = [];
      me.selectCrop = [];
      me.selectedMunicipality = [];
      me.selected = [];
      me.selectedLandUse = [];
      me.selectedTypography = [];
      me.selectedSoilType = [];
      me.selectedWater = [];
      me.heightFrom = null;
      me.heightUntil = null;
      me.distanceFrom = null;
      me.distanceUntil = null;
      me.filterIsDisabled = true;
      me.category.subcategories.forEach((el) => {
        el.isActive = false;
      });
      me.dataLandUse.forEach((y) => {
        y.state = false;
      });
      me.dataTypography.forEach((y) => {
        y.state = false;
      });
      me.dataSoilTypes.forEach((y) => {
        y.state = false;
      });
      me.dataWater.forEach((y) => {
        y.state = false;
      });
    },
    /**
     * perform the formula to convert hectares to squares, blocks and bushels
     * @returns {void}
     */
    hectaresBlocks(value) {
      if (value) {
        let isValidate = true;
        const formatter = new Intl.NumberFormat("es-CO", {
          minimumFractionDigits: 0,
        });

        if (value.split(",")[1]) {
          isValidate = false;
          value = value.split(".").join("").split(",").join(".");
        }

        if (isNaN(value.split(".").join(""))) {
          value = 0;
        } else if (value.split(".")[1] && isValidate) {
          value = value.split(".").join("");
        }

        value = (value * (1 / 0.64)).toFixed(1);
        let result = formatter.format(value);
        return result;
      }
    },
    applyFilter() {
      const me = this;
      me.fillQuery();

      me.$emit("applyFilter", me.filterSelect, {
        ...me.dataMoney,
        heightFrom: me.heightFrom,
        heightUntil: me.heightUntil,
        distanceFrom: me.distanceFrom,
        distanceUntil: me.distanceUntil,
      });
    },
    barterChange() {
      const me = this;

      if (me.barter == "1") {
        me.isPercentage = true;
      } else {
        // me.dataMoney.barterPercentage = null;
        // me.deleteSelectFilter(null, "barterPercentage", "barterPercentage");
        // me.isPercentage = false;
      }
    },
    loadSelected(type = null) {
      const me = this;
      let isFilter = false;

      if (me.searchCategoryId) {
        me.$emit("updateCategoryId", me.searchCategoryId);
        me.selected = [];
        me.filterSelect = [];
        me.category.subcategories.forEach((el) => {
          if (el.id == me.searchCategoryId) {
            el.isActive = true;
            me.selected.push(el.id);
            me.addEditDelSelectFilter(
              el.id,
              "filterTypeFarm",
              el.name,
              "card-farm-filter" + el.id
            );
          } else {
            el.isActive = false;
          }
        });
        isFilter = true;
        // if (me.searchCategoryId) isFilter = true;
      } else {
        if (!me.search && !me.searchCategoryId) {
          if (me.isKeyExists(me.$route.query, "tipos-de-fincas")) {
            if (me.$route.query["tipos-de-fincas"]) {
              const categories = Array.isArray(
                me.$route.query["tipos-de-fincas"]
              )
                ? me.$route.query["tipos-de-fincas"][0].split(",")
                : me.$route.query["tipos-de-fincas"].split(",");

              if (categories.length == 1) {
                me.$emit("updateCategoryId", categories[0].split("-")[1]);
              } else {
                me.$emit("updateCategoryId", null);
              }
              categories.forEach((y) => {
                const id = y.split("-")[1];
                me.category.subcategories.forEach((el) => {
                  if (el.id == id) {
                    el.isActive = true;
                    me.selected.push(el.id);
                    me.addEditDelSelectFilter(
                      el.id,
                      "filterTypeFarm",
                      el.name,
                      "card-farm-filter" + el.id
                    );
                  }
                });
              });
              isFilter = true;
            } else {
              me.$router.push("/no-se-encontro-lo-que-buscabas");
            }
          }
        } else {
          me.selected = [];
          me.filterSelect = [];
        }
      }

      if (!me.searchCategoryId && me.search) {
        me.clearFilter();
        me.$router.push({ query: { buscar: me.search } });
      } else {
        if (me.isKeyExists(me.$route.query, "departamentos")) {
          if (me.$route.query["departamentos"]) {
            const departaments = Array.isArray(me.$route.query["departamentos"])
              ? me.$route.query["departamentos"][0].split(",")
              : me.$route.query["departamentos"].split(",");
            departaments.forEach((el) => {
              const id = el.split("-")[1];
              const depart = me.departments.find((y) => y.id == id);
              if (depart) {
                me.addEditDelSelectFilter(
                  depart.id,
                  "filtroDepartamento",
                  depart.name,
                  "departments"
                );
                me.selectedDepart.push(depart);
                me.municipalities = [
                  ...me.municipalities,
                  ...depart.municipalities,
                ];
              }
            });
            me.municipalities.sort((a, b) => a.name.localeCompare(b.name));
            isFilter = true;
          } else {
            me.$router.push("/no-se-encontro-lo-que-buscabas");
          }
        }

        if (me.isKeyExists(me.$route.query, "municipios")) {
          if (me.$route.query["municipios"]) {
            const municipios = Array.isArray(me.$route.query["municipios"])
              ? me.$route.query["municipios"][0].split(",")
              : me.$route.query["municipios"].split(",");
            municipios.forEach((el) => {
              const id = el.split("-")[1];
              const municipy = me.municipalities.find((y) => y.id == id);
              if (municipy) {
                me.addEditDelSelectFilter(
                  municipy.id,
                  "filterMunicipie",
                  municipy.name,
                  "municipies"
                );
                me.selectedMunicipality.push(municipy);
              }
            });
            isFilter = true;
          } else {
            me.$router.push("/no-se-encontro-lo-que-buscabas");
          }
        }

        if (me.isKeyExists(me.$route.query, "usos-de-suelos")) {
          if (me.$route.query["usos-de-suelos"]) {
            const usoSuelos = Array.isArray(me.$route.query["usos-de-suelos"])
              ? me.$route.query["usos-de-suelos"][0].split(",")
              : me.$route.query["usos-de-suelos"].split(",");
            usoSuelos.forEach((el) => {
              const id = el.split("-")[1];
              me.dataLandUse.forEach((y) => {
                if (id == y.id) {
                  y.state = true;
                  me.addEditDelSelectFilter(y.id, "landUse", y.name);
                }
              });
            });
            isFilter = true;
          } else {
            me.$router.push("/no-se-encontro-lo-que-buscabas");
          }
        }

        if (me.isKeyExists(me.$route.query, "topografia")) {
          if (me.$route.query["topografia"]) {
            const topografias = Array.isArray(me.$route.query["topografia"])
              ? me.$route.query["topografia"][0].split(",")
              : me.$route.query["topografia"].split(",");
            topografias.forEach((el) => {
              const id = el.split("-")[1];
              me.dataTypography.forEach((y) => {
                if (id == y.id) {
                  me.addEditDelSelectFilter(y.id, "typography", y.name);
                }
              });
            });
            isFilter = true;
          } else {
            me.$router.push("/no-se-encontro-lo-que-buscabas");
          }
        }

        if (me.isKeyExists(me.$route.query, "tipo-de-suelos")) {
          if (me.$route.query["tipo-de-suelos"]) {
            const typeSoils = Array.isArray(me.$route.query["tipo-de-suelos"])
              ? me.$route.query["tipo-de-suelos"][0].split(",")
              : me.$route.query["tipo-de-suelos"].split(",");
            typeSoils.forEach((el) => {
              const id = el.split("-")[1];
              me.dataSoilTypes.forEach((y) => {
                if (id == y.id) {
                  me.addEditDelSelectFilter(y.id, "soilTypes", y.name);
                }
              });
            });
            isFilter = true;
          } else {
            me.$router.push("/no-se-encontro-lo-que-buscabas");
          }
        }

        if (me.isKeyExists(me.$route.query, "aguas")) {
          if (me.$route.query["aguas"]) {
            const aguas = Array.isArray(me.$route.query["aguas"])
              ? me.$route.query["aguas"][0].split(",")
              : me.$route.query["aguas"].split(",");
            aguas.forEach((el) => {
              const id = el.split("-")[1];
              me.dataWater.forEach((y) => {
                if (id == y.id) {
                  me.addEditDelSelectFilter(y.id, "water", y.name);
                }
              });
            });
            isFilter = true;
          } else {
            me.$router.push("/no-se-encontro-lo-que-buscabas");
          }
        }

        if (me.isKeyExists(me.$route.query, "pastos")) {
          if (me.$route.query["pastos"]) {
            const pastos = Array.isArray(me.$route.query["pastos"])
              ? me.$route.query["pastos"][0].split(",")
              : me.$route.query["pastos"].split(",");
            pastos.forEach((el) => {
              const id = el.split("-")[1];
              const grass = me.grasses.find((y) => y.id == id);
              if (grass) {
                me.addEditDelSelectFilter(
                  grass.id,
                  "filterGrasses",
                  grass.name,
                  "grasses"
                );
                me.selectGrasse.push(grass);
              }
            });
            isFilter = true;
          } else {
            me.$router.push("/no-se-encontro-lo-que-buscabas");
          }
        }

        if (me.isKeyExists(me.$route.query, "cultivos")) {
          if (me.$route.query["cultivos"]) {
            const cultivos = Array.isArray(me.$route.query["cultivos"])
              ? me.$route.query["cultivos"][0].split(",")
              : me.$route.query["cultivos"].split(",");
            cultivos.forEach((el) => {
              const id = el.split("-")[1];
              const crop = me.crops.find((y) => y.id == id);
              if (crop) {
                me.addEditDelSelectFilter(
                  crop.id,
                  "filterCrops",
                  crop.name,
                  "crops"
                );
                me.selectCrop.push(crop);
              }
            });
            isFilter = true;
          } else {
            me.$router.push("/no-se-encontro-lo-que-buscabas");
          }
        }

        if (me.isKeyExists(me.$route.query, "area-desde")) {
          if (me.$route.query["area-desde"]) {
            me.dataMoney.areaFrom = me.$route.query["area-desde"];
            me.dataMoney.areaFrom = Array.isArray(me.dataMoney.areaFrom)
              ? me.dataMoney.areaFrom[0]
              : me.dataMoney.areaFrom;

            me.addEditDelSelectFilter(
              null,
              "areaFrom",
              me.dataMoney.areaFrom,
              "areaFrom"
            );
            isFilter = true;
          } else {
            me.$router.push("/no-se-encontro-lo-que-buscabas");
          }
        }

        if (me.isKeyExists(me.$route.query, "area-hasta")) {
          if (me.$route.query["area-hasta"]) {
            me.dataMoney.areaUntil = me.$route.query["area-hasta"];
            me.dataMoney.areaUntil = Array.isArray(me.dataMoney.areaUntil)
              ? me.dataMoney.areaUntil[0]
              : me.dataMoney.areaUntil;

            me.addEditDelSelectFilter(
              null,
              "areaUntil",
              me.dataMoney.areaUntil,
              "areaUntil"
            );
            isFilter = true;
          } else {
            me.$router.push("/no-se-encontro-lo-que-buscabas");
          }
        }

        if (me.isKeyExists(me.$route.query, "precio-heactarea-desde")) {
          if (me.$route.query["precio-heactarea-desde"]) {
            me.dataMoney.priceHectareFrom = me.formatThousand(
              me.$route.query["precio-heactarea-desde"]
            );
            me.addEditDelSelectFilter(
              null,
              "priceHectareFrom",
              me.dataMoney.priceHectareFrom,
              "priceHectareFrom"
            );
            isFilter = true;
          } else {
            me.$router.push("/no-se-encontro-lo-que-buscabas");
          }
        }

        if (me.isKeyExists(me.$route.query, "precio-hectarea-hasta")) {
          if (me.$route.query["precio-hectarea-hasta"]) {
            me.dataMoney.priceHectareUntil = me.formatThousand(
              me.$route.query["precio-hectarea-hasta"]
            );
            me.addEditDelSelectFilter(
              null,
              "priceHectareUntil",
              me.dataMoney.priceHectareUntil,
              "priceHectareUntil"
            );
            isFilter = true;
          } else {
            me.$router.push("/no-se-encontro-lo-que-buscabas");
          }
        }

        if (me.isKeyExists(me.$route.query, "precio-total-desde")) {
          if (me.$route.query["precio-total-desde"]) {
            me.dataMoney.priceTotalFrom = me.formatThousand(
              me.$route.query["precio-total-desde"]
            );
            me.addEditDelSelectFilter(
              null,
              "priceTotalFrom",
              me.dataMoney.priceTotalFrom,
              "priceTotalFrom"
            );
            isFilter = true;
          } else {
            me.$router.push("/no-se-encontro-lo-que-buscabas");
          }
        }

        if (me.isKeyExists(me.$route.query, "precio-total-hasta")) {
          if (me.$route.query["precio-total-hasta"]) {
            me.dataMoney.priceTotalUntil = me.formatThousand(
              me.$route.query["precio-total-hasta"]
            );
            me.addEditDelSelectFilter(
              null,
              "priceTotalUntil",
              me.dataMoney.priceTotalUntil,
              "priceTotalUntil"
            );
            isFilter = true;
          } else {
            me.$router.push("/no-se-encontro-lo-que-buscabas");
          }
        }

        if (me.isKeyExists(me.$route.query, "porcentanje-permuta")) {
          if (me.$route.query["porcentanje-permuta"]) {
            me.dataMoney.barterPercentage =
              me.$route.query["porcentanje-permuta"];
            me.addEditDelSelectFilter(
              null,
              "barterPercentage",
              me.dataMoney.barterPercentage + " %",
              "barterPercentage"
            );
            me.barter = "1";
            me.isPercentage = true;
            isFilter = true;
          } else {
            me.$router.push("/no-se-encontro-lo-que-buscabas");
          }
        }

        if (me.isKeyExists(me.$route.query, "altura-desde")) {
          if (me.$route.query["altura-desde"]) {
            me.heightFrom = me.$route.query["altura-desde"];
            me.addEditDelSelectFilter(
              null,
              "heightFrom",
              me.heightFrom,
              "heightFrom"
            );
            isFilter = true;
          } else {
            me.$router.push("/no-se-encontro-lo-que-buscabas");
          }
        }

        if (me.isKeyExists(me.$route.query, "altura-hasta")) {
          if (me.$route.query["altura-hasta"]) {
            me.heightUntil = me.$route.query["altura-hasta"];
            me.addEditDelSelectFilter(
              null,
              "heightUntil",
              me.heightUntil,
              "heightUntil"
            );
            isFilter = true;
          } else {
            me.$router.push("/no-se-encontro-lo-que-buscabas");
          }
        }

        if (me.isKeyExists(me.$route.query, "distacia-desde")) {
          if (me.$route.query["distacia-desde"]) {
            me.distanceFrom = me.$route.query["distacia-desde"];

            me.addEditDelSelectFilter(
              null,
              "distanceFrom",
              me.distanceFrom,
              "distanceFrom"
            );
            isFilter = true;
          } else {
            me.$router.push("/no-se-encontro-lo-que-buscabas");
          }
        }

        if (me.isKeyExists(me.$route.query, "distacia-hasta")) {
          if (me.$route.query["distacia-hasta"]) {
            me.distanceUntil = me.$route.query["distacia-hasta"];
            me.addEditDelSelectFilter(
              null,
              "distanceUntil",
              me.distanceUntil,
              "distanceUntil"
            );
            isFilter = true;
          } else {
            me.$router.push("/no-se-encontro-lo-que-buscabas");
          }
        }
      }

      if (isFilter) {
        me.applyFilter();
      } else {
        me.$emit("fillFilterSuccess", type);
      }
    },
    isKeyExists(obj, key) {
      return Object.prototype.hasOwnProperty.call(obj, key);
    },
    fillQuery() {
      const me = this;
      const querys = [];

      me.$router.push({ query: null }); //delete all filters

      // Filtro tipos de finca
      const typeFarms = me.filterSelect.filter(
        (el) => el.typeFilter == "filterTypeFarm"
      );

      if (typeFarms.length > 0) {
        const filters = [];
        typeFarms.forEach((el) => {
          filters.push(
            `${el.text
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .toLowerCase()}-${el.id}`
          );
        });
        querys.push({
          name: "tipos-de-fincas",
          value: filters,
        });
      }

      // Filtro departamentos
      const departaments = me.filterSelect.filter(
        (el) => el.typeFilter == "filtroDepartamento"
      );
      if (departaments.length > 0) {
        const filters = [];
        departaments.forEach((el) => {
          filters.push(
            `${el.text
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .toLowerCase()}-${el.id}`
          );
        });
        querys.push({
          name: "departamentos",
          value: filters,
        });
      }

      // Filtro municipios
      const municipalities = me.filterSelect.filter(
        (el) => el.typeFilter == "filterMunicipie"
      );

      if (municipalities.length > 0) {
        const filters = [];
        municipalities.forEach((el) => {
          filters.push(`${el.text}-${el.id}`);
        });
        querys.push({
          name: "municipios",
          value: filters,
        });
      }

      // Filtro por area

      const areaFrom = me.filterSelect.find(
        (el) => el.typeFilter == "areaFrom"
      );

      const areaUntil = me.filterSelect.find(
        (el) => el.typeFilter == "areaUntil"
      );

      if (areaFrom) {
        querys.push({
          name: "area-desde",
          value: me.dataMoney.areaFrom,
          // value: Number(
          //   me.dataMoney.areaFrom.split(".").join("").split(",").join(".")
          // ),
        });
      }

      if (areaUntil) {
        querys.push({
          name: "area-hasta",
          value: me.dataMoney.areaUntil,
          // value: Number(
          //   me.dataMoney.areaUntil.split(".").join("").split(",").join(".")
          // ),
        });
      }

      // Filtro precio hectarea desde - hasta

      const priceHectareFrom = me.filterSelect.find(
        (el) => el.typeFilter == "priceHectareFrom"
      );

      const priceHectareUntil = me.filterSelect.find(
        (el) => el.typeFilter == "priceHectareUntil"
      );

      if (priceHectareFrom) {
        querys.push({
          name: "precio-heactarea-desde",
          value: Number(
            me.dataMoney.priceHectareFrom.split("$")[1].split(".").join("")
          ),
        });
      }

      if (priceHectareUntil) {
        querys.push({
          name: "precio-hectarea-hasta",
          value: Number(
            me.dataMoney.priceHectareUntil.split("$")[1].split(".").join("")
          ),
        });
      }

      // Filtro precio total desde - hasta

      const priceTotalFrom = me.filterSelect.find(
        (el) => el.typeFilter == "priceTotalFrom"
      );

      const priceTotalUntil = me.filterSelect.find(
        (el) => el.typeFilter == "priceTotalUntil"
      );

      if (priceTotalFrom) {
        querys.push({
          name: "precio-total-desde",
          value: Number(
            me.dataMoney.priceTotalFrom.split("$")[1].split(".").join("")
          ),
        });
      }

      if (priceTotalUntil) {
        querys.push({
          name: "precio-total-hasta",
          value: Number(
            me.dataMoney.priceTotalUntil.split("$")[1].split(".").join("")
          ),
        });
      }

      // Filtro porcentaje de permuta
      const barterPercentage = me.filterSelect.find(
        (el) => el.typeFilter == "barterPercentage"
      );

      if (barterPercentage) {
        querys.push({
          name: "porcentanje-permuta",
          value: parseFloat(me.dataMoney.barterPercentage),
        });
      }
      // Filtro uso de suelo
      const landUse = me.filterSelect.filter(
        (el) => el.typeFilter == "landUse"
      );

      if (landUse.length > 0) {
        const filters = [];
        landUse.forEach((el) => {
          filters.push(
            `${el.text
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .toLowerCase()}-${el.id}`
          );
        });
        querys.push({
          name: "usos-de-suelos",
          value: filters,
        });
      }
      // Filtro topografia
      const typography = me.filterSelect.filter(
        (el) => el.typeFilter == "typography"
      );

      if (typography.length > 0) {
        const filters = [];
        typography.forEach((el) => {
          filters.push(
            `${el.text
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .toLowerCase()}-${el.id}`
          );
        });
        querys.push({
          name: "topografia",
          value: filters,
        });
      }
      // Filtro tipos de suelos
      const soilTypes = me.filterSelect.filter(
        (el) => el.typeFilter == "soilTypes"
      );

      if (soilTypes.length > 0) {
        const filters = [];
        soilTypes.forEach((el) => {
          filters.push(
            `${el.text
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .toLowerCase()}-${el.id}`
          );
        });
        querys.push({
          name: "tipo-de-suelos",
          value: filters,
        });
      }
      // Filtro aguas
      const water = me.filterSelect.filter((el) => el.typeFilter == "water");

      if (water.length > 0) {
        const filters = [];
        water.forEach((el) => {
          filters.push(
            `${el.text
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .toLowerCase()}-${el.id}`
          );
        });
        querys.push({
          name: "aguas",
          value: filters,
        });
      }
      // Filtro de pastos
      const filterGrasses = me.filterSelect.filter(
        (el) => el.typeFilter == "filterGrasses"
      );

      if (filterGrasses.length > 0) {
        const filters = [];
        filterGrasses.forEach((el) => {
          filters.push(
            `${el.text
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .toLowerCase()}-${el.id}`
          );
        });
        querys.push({
          name: "pastos",
          value: filters,
        });
      }
      // Filtro de cultivos
      const filterCrops = me.filterSelect.filter(
        (el) => el.typeFilter == "filterCrops"
      );

      if (filterCrops.length > 0) {
        const filters = [];
        filterCrops.forEach((el) => {
          filters.push(
            `${el.text
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .toLowerCase()}-${el.id}`
          );
        });
        querys.push({
          name: "cultivos",
          value: filters,
        });
      }

      // Filtro de altura
      const heightFrom = me.filterSelect.find(
        (el) => el.typeFilter == "heightFrom"
      );

      const heightUntil = me.filterSelect.find(
        (el) => el.typeFilter == "heightUntil"
      );

      if (heightFrom) {
        querys.push({
          name: "altura-desde",
          value: Number(me.heightFrom.split(".").join("")),
        });
      }

      if (heightUntil) {
        querys.push({
          name: "altura-hasta",
          value: Number(me.heightUntil.split(".").join("")),
        });
      }

      // Filtro distacia via pavimentada
      const distanceFrom = me.filterSelect.find(
        (el) => el.typeFilter == "distanceFrom"
      );

      const distanceUntil = me.filterSelect.find(
        (el) => el.typeFilter == "distanceUntil"
      );

      if (distanceFrom) {
        querys.push({
          name: "distacia-desde",
          value: Number(me.distanceFrom.split(".").join("")),
        });
      }

      if (distanceUntil) {
        querys.push({
          name: "distacia-hasta",
          value: Number(me.distanceUntil.split(".").join("")),
        });
      }

      let query = null;
      querys.forEach((el) => {
        query = {
          ...query,
          [el.name]: [el.value],
        };
      });

      me.$router.push({ name: "ListOfFarms", query });
    },
    validationPriceHect1() {
      const me = this;
      let isError = false;
      let price1 = 0;
      let price2 = 0;
      if (
        me.dataMoney.priceHectareFrom &&
        me.dataMoney.priceHectareFrom.split("$")[1]
      ) {
        price1 = me.dataMoney.priceHectareFrom
          .split("$")[1]
          .split(".")
          .join("");
      }

      if (
        me.dataMoney.priceHectareUntil &&
        me.dataMoney.priceHectareUntil.split("$")[1]
      ) {
        price2 = me.dataMoney.priceHectareUntil
          .split("$")[1]
          .split(".")
          .join("");
      }

      if (Number(price1) > Number(price2) && Number(price2) > 0) {
        isError = true;
        me.dataMoney.priceHectareFrom = 0;
        me.filterSelect.forEach((el, index) => {
          if (el.typeFilter == "priceHectareFrom") {
            me.filterSelect.splice(index, 1);
          }
        });
      } else {
        isError = false;
      }

      me.isValidationPriceHect1 = isError;
    },
    validationPriceHect2() {
      const me = this;
      let isError = false;
      let price1 = 0;
      let price2 = 0;

      if (
        me.dataMoney.priceHectareFrom &&
        me.dataMoney.priceHectareFrom.split("$")[1]
      ) {
        price1 = me.dataMoney.priceHectareFrom
          .split("$")[1]
          .split(".")
          .join("");
      }

      if (
        me.dataMoney.priceHectareUntil &&
        me.dataMoney.priceHectareUntil.split("$")[1]
      ) {
        price2 = me.dataMoney.priceHectareUntil
          .split("$")[1]
          .split(".")
          .join("");
      }

      if (Number(price2) < Number(price1) && Number(price2) > 0) {
        isError = true;
        me.dataMoney.priceHectareUntil = 0;
        me.filterSelect.forEach((el, index) => {
          if (el.typeFilter == "priceHectareUntil") {
            me.filterSelect.splice(index, 1);
          }
        });
      } else {
        isError = false;
      }

      me.isValidationPriceHect2 = isError;
    },
    validationPriceTotal1() {
      const me = this;
      let isError = false;

      let price1 = 0;
      let price2 = 0;
      if (
        me.dataMoney.priceTotalFrom &&
        me.dataMoney.priceTotalFrom.split("$")[1]
      ) {
        price1 = me.dataMoney.priceTotalFrom.split("$")[1].split(".").join("");
      }

      if (
        me.dataMoney.priceTotalUntil &&
        me.dataMoney.priceTotalUntil.split("$")[1]
      ) {
        price2 = me.dataMoney.priceTotalUntil.split("$")[1].split(".").join("");
      }

      if (Number(price1) > Number(price2) && Number(price2) > 0) {
        isError = true;
        me.dataMoney.priceTotalFrom = 0;
        me.filterSelect.forEach((el, index) => {
          if (el.typeFilter == "priceTotalFrom") {
            me.filterSelect.splice(index, 1);
          }
        });
      } else {
        isError = false;
      }

      me.isValidationPriceTotal1 = isError;
    },
    validationPriceTotal2() {
      const me = this;
      let isError = false;
      let price1 = 0;
      let price2 = 0;

      if (
        me.dataMoney.priceTotalFrom &&
        me.dataMoney.priceTotalFrom.split("$")[1]
      ) {
        price1 = me.dataMoney.priceTotalFrom.split("$")[1].split(".").join("");
      }

      if (
        me.dataMoney.priceTotalUntil &&
        me.dataMoney.priceTotalUntil.split("$")[1]
      ) {
        price2 = me.dataMoney.priceTotalUntil.split("$")[1].split(".").join("");
      }

      if (Number(price2) < Number(price1) && Number(price2) > 0) {
        isError = true;
        me.dataMoney.priceTotalUntil = 0;
        me.filterSelect.forEach((el, index) => {
          if (el.typeFilter == "priceTotalUntil") {
            me.filterSelect.splice(index, 1);
          }
        });
      } else {
        isError = false;
      }

      me.isValidationPriceTotal2 = isError;
    },
    validationArea1() {
      const me = this;
      let isError = false;
      let area1 = me.dataMoney.areaFrom;
      let area2 = me.dataMoney.areaUntil;

      if (me.dataMoney.areaFrom && me.dataMoney.areaFrom.split(".")[1]) {
        area1 = me.dataMoney.areaFrom.split(".").join("");
      }

      if (me.dataMoney.areaFrom && me.dataMoney.areaFrom.split(",")[1]) {
        area1 = me.dataMoney.areaFrom.split(".").join("").split(",").join(".");
      }

      if (me.dataMoney.areaUntil && me.dataMoney.areaUntil.split(".")[1]) {
        area2 = me.dataMoney.areaUntil.split(".").join("").split(",").join(".");
      }

      if (me.dataMoney.areaUntil && me.dataMoney.areaUntil.split(",")[1]) {
        area2 = me.dataMoney.areaUntil.split(".").join("").split(",").join(".");
      }

      if (parseFloat(area1) > parseFloat(area2) && parseFloat(area2) > 0) {
        isError = true;
        me.dataMoney.areaFrom = 0;
      } else {
        isError = false;
      }
      me.isValidationArea1 = isError;
    },
    validationArea2() {
      const me = this;
      let isError = false;
      let area1 = me.dataMoney.areaFrom;
      let area2 = me.dataMoney.areaUntil;

      if (me.dataMoney.areaFrom && me.dataMoney.areaFrom.split(".")[1]) {
        area1 = me.dataMoney.areaFrom.split(".").join("");
      }

      if (me.dataMoney.areaFrom && me.dataMoney.areaFrom.split(",")[1]) {
        area1 = me.dataMoney.areaFrom.split(".").join("").split(",").join(".");
      }

      if (me.dataMoney.areaUntil && me.dataMoney.areaUntil.split(".")[1]) {
        area2 = me.dataMoney.areaUntil.split(".").join("").split(",").join(".");
      }

      if (me.dataMoney.areaUntil && me.dataMoney.areaUntil.split(",")[1]) {
        area2 = me.dataMoney.areaUntil.split(".").join("").split(",").join(".");
      }

      if (parseFloat(area2) < parseFloat(area1) && parseFloat(area2) > 0) {
        isError = true;
        me.dataMoney.areaUntil = 0;
      } else {
        isError = false;
      }
      me.isValidationArea2 = isError;
    },
    validationHeightMin() {
      const me = this;
      let isError = false;

      let min = 0;
      let max = 0;
      if (me.heightFrom) {
        min = me.heightFrom.split(".").join("");
      }

      if (me.heightUntil) {
        max = me.heightUntil.split(".").join("");
      }

      if (Number(min) > Number(max) && Number(max) > 0) {
        isError = true;
        me.heightFrom = 0;
        me.filterSelect.forEach((el, index) => {
          if (el.typeFilter == "heightFrom") {
            me.filterSelect.splice(index, 1);
          }
        });
      } else {
        isError = false;
      }

      me.isValidationMin = isError;
    },
    validationHeightMax() {
      const me = this;
      let isError = false;
      let min = 0;
      let max = 0;

      if (me.heightFrom) {
        min = me.heightFrom.split(".").join("");
      }

      if (me.heightUntil) {
        max = me.heightUntil.split(".").join("");
      }

      if (Number(max) < Number(min) && Number(max) > 0) {
        isError = true;
        me.heightUntil = 0;
        me.filterSelect.forEach((el, index) => {
          if (el.typeFilter == "heightUntil") {
            me.filterSelect.splice(index, 1);
          }
        });
      } else {
        isError = false;
      }

      me.isValidationMax = isError;
    },
    validationDistanceMin() {
      const me = this;
      let isError = false;

      let min = 0;
      let max = 0;
      if (me.distanceFrom) {
        min = me.distanceFrom.split(".").join("");
      }

      if (me.distanceUntil) {
        max = me.distanceUntil.split(".").join("");
      }

      if (Number(min) > Number(max) && Number(max) > 0) {
        isError = true;
        me.distanceFrom = 0;
        me.filterSelect.forEach((el, index) => {
          if (el.typeFilter == "distanceFrom") {
            me.filterSelect.splice(index, 1);
          }
        });
      } else {
        isError = false;
      }

      me.isValidationDistanceMin = isError;
    },
    validationDistanceMax() {
      const me = this;
      let isError = false;
      let min = 0;
      let max = 0;

      if (me.distanceFrom) {
        min = me.distanceFrom.split(".").join("");
      }

      if (me.distanceUntil) {
        max = me.distanceUntil.split(".").join("");
      }

      if (Number(max) < Number(min) && Number(max) > 0) {
        isError = true;
        me.distanceUntil = 0;
        me.filterSelect.forEach((el, index) => {
          if (el.typeFilter == "distanceUntil") {
            me.filterSelect.splice(index, 1);
          }
        });
      } else {
        isError = false;
      }

      me.isValidationDistanceMax = isError;
    },
    async listDetailAttributes() {
      const me = this;
      try {
        const dataAditional = await generateHash();
        const formData = {
          attributes: [
            "USO_SUELOS",
            "TOPOGRAFIA",
            "AGUAS",
            "PASTOS",
            "CULTIVOS",
            "TIPO_SUELOS",
          ],
          ...dataAditional,
        };
        const {
          data: { data },
        } = await me.listOfFarmRepository.getAll(formData);

        data.forEach((el) => {
          if (el.id_name == "AGUAS") {
            el.subattributes.forEach((el) => (el.state = false));
            me.dataWater = el.subattributes;
          } else if (el.id_name == "CULTIVOS") {
            el.subattributes.forEach((el) => (el.state = false));
            me.crops = el.subattributes;
          } else if (el.id_name == "TOPOGRAFIA") {
            el.subattributes.forEach((el) => (el.state = false));
            me.dataTypography = el.subattributes;
          } else if (el.id_name == "PASTOS") {
            el.subattributes.forEach((el) => (el.state = false));
            me.grasses = el.subattributes;
          } else if (el.id_name == "TIPO_SUELOS") {
            el.subattributes.forEach((el) => (el.state = false));
            me.dataSoilTypes = el.subattributes;
          } else {
            el.subattributes.forEach((el) => (el.state = false));
            me.dataLandUse = el.subattributes;
          }
        });
      } catch (error) {
        // console.log(error);
      }
    },
    addDeleteSelectCheckbox(id, type, state) {
      const me = this;
      if (type == "landUse") {
        if (state) {
          me.selectedLandUse.push(id);
          me.dataLandUse.forEach((el) => {
            if (el.id == id) el.state = true;
          });
        } else {
          me.selectedLandUse.forEach((el, index) => {
            if (el == id) {
              me.selectedLandUse.splice(index, 1);
              me.dataLandUse.forEach((y) => {
                if (y.id == id) y.state = false;
              });
            }
          });
        }
      } else if (type == "typography") {
        if (state) {
          me.selectedTypography.push(id);
          me.dataTypography.forEach((el) => {
            if (el.id == id) el.state = true;
          });
        } else {
          me.selectedTypography.forEach((el, index) => {
            if (el == id) {
              me.selectedTypography.splice(index, 1);
              me.dataTypography.forEach((y) => {
                if (y.id == id) y.state = false;
              });
            }
          });
        }
      } else if (type == "soilTypes") {
        if (state) {
          me.selectedSoilType.push(id);
          me.dataSoilTypes.forEach((el) => {
            if (el.id == id) el.state = true;
          });
        } else {
          me.selectedSoilType.forEach((el, index) => {
            if (el == id) {
              me.selectedSoilType.splice(index, 1);
              me.dataSoilTypes.forEach((y) => {
                if (y.id == id) y.state = false;
              });
            }
          });
        }
      } else {
        // water
        if (state) {
          me.selectedWater.push(id);
          me.dataWater.forEach((el) => {
            if (el.id == id) el.state = true;
          });
        } else {
          me.selectedWater.forEach((el, index) => {
            if (el == id) {
              me.selectedWater.splice(index, 1);
              me.dataWater.forEach((y) => {
                if (y == id) y.state = false;
              });
            }
          });
        }
      }
    },
    back() {
      const me = this;
      me.loadSelected();
    },
  },
  async mounted() {
    const me = this;
    await me.loadCategories();
    await me.loadDepartments();
    await me.listDetailAttributes();
    me.loadSelected("initial");
    // window.onpopstate = () => {
    //   me.back();
    // };
  },
};
</script>
<style lang="css">
.style-chooser4 .vs__dropdown-toggle {
  background-color: white;
  padding: 0 !important;
  min-height: 22px;
}
.style-chooser6 .vs__selected {
  color: black !important;
}
.max-w-110 {
  max-width: 110px !important;
}
.style-chooser6 .vs__search {
  color: black !important;
}
.style-chooser6 .vs__search:focus {
  border-color: transparent !important;
}
</style>
