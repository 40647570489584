<template>
  <div>
    <div class="pl-3 lg:pl-0 md:pl-0">
      <button
        @click.prevent="openModal()"
        class="flex items-center p-2 bg-agrogo-green-3 rounded-md"
      >
        <span class="icon-share text-agrogo-yellow-1"></span>
      </button>
    </div>
    <modal-share
      :isModal="isModal"
      :link="link"
      @closeModal="isModal = false"
    />
  </div>
</template>
<script>
import ModalShare from "./ModalShare";

export default {
  components: {
    ModalShare,
  },
  data() {
    return {
      isModal: false,
      link: null,
    };
  },
  methods: {
    openModal() {
      const me = this;
      me.link = window.location.href;
      me.isModal = true;
    },
  },
};
</script>
